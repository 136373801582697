import React from "react";
import { connect } from "react-redux";
import { searchByName, setSelectedTree } from "../../actions/btSearch";

import "./BtSearchByName.css";
import { FaSearch, FaArrowRight } from "react-icons/fa";
import htmlParser from 'react-html-parser';
import BtSearchByNameSuggestionBox from "./BtSearchByNameSuggestionBox";
import { getSearchOptions, setSearchOptions } from "../../utilities/btSessionStorage"
import qs from 'query-string';
import { withRouter } from 'react-router'

class BtSearchByName extends React.Component {
  constructor(props) {
    super();
    this.formRef = React.createRef();


    let loc = window.location.href.split('/');
    let searchTerm = ""
    if (loc[4] === 'name-search') {
      searchTerm = loc[5].split('?')[0].replace('%20', ' ')
    }

    this.state = {
      selectedTreeId: null,
      matchFound: false,
      showSuggestions: false,
      searchTerm: searchTerm,
      hoverEntry: -1,
      reset: false,
    }
  }

  // actions are passed in as props
  componentDidMount() {
    this.search();
    this.setKeyHandlers();
  }

  setKeyHandlers = () => {
    document.addEventListener('keyup', this.moveKeyListener)
  }

  //The listener for the keyboard
  moveKeyListener = async (e) => {
    try {
      //If the key is arrow up or down, set the "direction" of the hover 
      let moveDirection = e.key === "ArrowUp" ? -1 : (e.key === "ArrowDown" ? 1 : null)

      //Check to see if the suggestions are showing, otherwise it eliminates the ability to scroll the page with arrows.
      if (this.state.showSuggestions) {
        if (moveDirection) {
          //Prevent the page from moving with arrows
          e.preventDefault();

          //Calculate which entry should be hovered over and make sure it's in bounds of 0 and the length of suggestions
          let hoverEntry = this.state.hoverEntry
          hoverEntry += moveDirection
          let len = this.filterSuggestions().length
          hoverEntry = hoverEntry < 0 ? len - 1 : (hoverEntry >= len ? 0 : hoverEntry)

          //Find which card is hovered over and pull the correct information
          let treeCard = this.filterSuggestions()[hoverEntry]

          let selectedTreeId = treeCard.option.tree_id
          let treeName = treeCard.option.name_concat

          //Update the state and "tree selection"- what's shown in the input box
          await this.setState({ hoverEntry, selectedTreeId, matchFound: true })
          this.updateTreeSelection(treeName, selectedTreeId)

          //Check to make sure the scroll box is in the accurate spot (otherwise the hover goes out of bounds of the box)
          let selectedBox = document.querySelector(`div[data-tree-id="${selectedTreeId}"][data-list-position="${hoverEntry}"]`)

          let selectionPixelsFromTop = selectedBox.offsetTop
          let wholeList = selectedBox.parentElement

          //This line is a hack because browsers have bugs with scrolling to 0
          if (selectionPixelsFromTop === 0) { this.setState({ reset: !this.state.reset }) }

          //Actually calculate to see if the box is in bounds (moving up and down)
          if ((wholeList.clientHeight + wholeList.scrollTop) < selectionPixelsFromTop) {
            wholeList.scrollTop = selectionPixelsFromTop - wholeList.clientHeight + selectedBox.clientHeight
          }
          else if (selectionPixelsFromTop < wholeList.scrollTop) {
            wholeList.scrollTop = selectionPixelsFromTop
          }
        }
      }

      //Listener for enter- make sure to use ref because there can be several forms on the same page
      if (e.key === "Enter" && this.formRef.current === document.activeElement) {
        this.submitForm()
      }


    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.moveKeyListener: ${err}`);
      return;
    }

  }

  //Listener for if the user moves their mouse over an entry
  moveMouseHover = (e) => {
    try {
      //Grab the right entry and pull the info
      let hoverEntry = parseInt(e.currentTarget.getAttribute('data-list-position'))
      let selectedTreeId = parseInt(e.currentTarget.getAttribute('data-tree-id'))
      let treeName = e.currentTarget.getAttribute('data-tree-name')

      //Update the state to make sure arrow keys & mouse work together
      if (hoverEntry !== this.state.hoverEntry || selectedTreeId !== this.state.selectedTreeId) {
        this.setState({ hoverEntry, selectedTreeId, matchFound: true })
        this.updateTreeSelection(treeName, selectedTreeId)
      }
    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.moveMouseHover: ${err}`);
      return;
    }
  }

  //Function to update the input box
  updateTreeSelection = (treeName, selectedTreeId) => {
    try {
      let searchInputs = document.getElementsByClassName('search-by-name-input');

      // setTimeout is a workaround for the onBlur function of the input
      setTimeout(() => {
        for (let search of searchInputs) {
          // there can be multiple search inputs on a page, all should show the current selection
          let cleanText = treeName.replace(/<\/?[^>]+(>|$)/g, "");
          cleanText = cleanText.replace("&times", "x");
          search.setAttribute('data-tree-id', selectedTreeId);
          search.value = cleanText
        }
      }, 0);
    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.updateTreeSelection: ${err}`);
      return;
    }
  }

  hideSuggestionBox = (e) => {
    try {
      this.setState({ showSuggestions: false })
    } catch (err) {
      console.log(`components.SearchByName.hideSuggestionBox: ${err}`);
      return;
    }
  }

  showSuggestionBox = (e) => {
    try {
      this.search(e)
      this.setState({ showSuggestions: true })
    } catch (err) {
      console.log(`components.SearchByName.showSuggestionBox: ${err}`);
      return;
    }
  }

  search = async (e) => {
    try {
      //Hiding the suggestions before gives the appearance of loading.
      this.hideSuggestionBox()
      let input = "";

      if (e && e.target) {
        input = e.target.value || ""

        this.setState({ searchTerm: input })

        let region = 'all';

        //If there's input and there's more than 1 character...
        if (input) {
          if (input.length > 1) {
            //...Do the search
            input = input.replace("'", '\'');
            //Wait for the results before showing the suggestions.
            await this.props.searchByName(input, region)
            this.showSuggestionBox()
          }
        } else {
          // calling searchByName with an empty string clears props.suggestions
          this.setState({ selectedTreeId: null, matchFound: false })
          return this.props.searchByName();
        }
      }
    } catch (err) {
      console.log(`components.SearchByName.search: ${err}`);
      return;
    }

  }

  submitForm = () => {
    try {
      // this handles pressing enter in the search form
      try {
        this.setScrollY();
        const params = qs.parse(window.location.search);
        const searchTerm = this.state.searchTerm;
        // reset active page on new search
        const activePage = 1;
        const sort = params.sort || 1;
        const resultsPerPage = params.resultsPerPage || 12;
        window.location.href = `/search-results/?searchTerm=${encodeURIComponent(searchTerm)}&activePage=${activePage}&resultsPerPage=${resultsPerPage}&sort=${sort}`
      } catch (err) {
        console.log(`component.BtSearchByName.chooseClickHandler: ${err}`);
      }
    } catch (err) {
      console.log(`components.SearchByName.getTreeDetailUrl: ${err}`);
      return null;
    }

  }


  //The big function that sorts & organizes all the information for the search.
  filterSuggestions = () => {
    try {
      if (this.state && this.state.searchTerm) {
        let suggestions = this.props.suggestions || [];

        let organizedResults = suggestions.map((result) => {
          let res = null

          // only display synonyms if primary scientific not present
          if (result.t_sequence === 1) {
            // only display akas if primary common name not present
            if (result.c_sequence === 1) {
              res = this.getSearchInfo(result)
            } else {
              if (!this.commonNameInResultSet(suggestions, result)) {
                res = this.getSearchInfo(result)
              }
            }

          } else {
            if (!this.primaryNameInResultSet(suggestions, result)) {
              res = this.getSearchInfo(result)
            }
          }

          return res
        })

        // add filteredResults to state
        //Filter out all the falsey values before returning.
        return organizedResults.filter(Boolean);
      }

    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.filterSuggestions: ${err}`);
      return;
    }

  }

  //This function takes in a result option, and calculates what information needs to be shown,
  //putting it into an object with the option itself.
   getSearchInfo = (option) => {
      try {
         let info = []

         //If they are both equal to 1, that means it's the best option to show, so show it.
         if (option.accepted_scientific === option.name_concat && 
            option.accepted_common === option.common) { 
            info = [
               option.accepted_scientific,
               option.accepted_common,
            ]

         } else if (option.accepted_scientific !== option.name_concat) {
            info = [
               option.accepted_scientific,
               <div>Synonym for: {htmlParser(option.name_concat)}</div>,
               option.common.toUpperCase()
            ]
         } else { 
            info = [
               option.accepted_scientific,
               option.accepted_common,
               `AKA: ${option.common.toUpperCase()}`
            ]
         } 
         if (info.length > 1) { return { option, info } }

      } catch (err) {
         console.log(`components.SearchByNameSuggestionBox.getSearchInfo: ${err}`);
         return;
      }
   }

  primaryNameInResultSet = (resultSet, result) => {
    try {
      let response = resultSet.find(r => (r.tree_id === result.tree_id || r.redirect === result.tree_id || r.tree_id === result.redirect) && r.t_sequence === 1);
      return response;
    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.primaryNameInResultSet: ${err}`);
      return;
    }
  }

  commonNameInResultSet = (resultSet, result) => {
    try {
      let response = resultSet.find(r => (r.tree_id === result.tree_id || r.redirect === result.tree_id || r.tree_id === result.redirect) && r.c_sequence === 1);
      return response;
    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.commonNameInResultSet: ${err}`);
      return;
    }
  }

  //Quick clear of the hover, input, and match.
  clearSearch = (e) => {
    try {
      e.preventDefault()

      this.setState({ hoverEntry: -1, selectedTreeId: null, matchFound: false })
      //Update the input with the original search term instead the chosen tree
      this.updateTreeSelection(this.state.searchTerm, null)
    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.moveKeyListener: ${err}`);
      return;
    }
  }

  // prevents page from jumping around on submit
  setScrollY = (e) => {
    try {
      let searchOptions = getSearchOptions();
      searchOptions.scroll_y = window.scrollY;
      setSearchOptions(searchOptions);
    } catch (err) {
      console.log(`components.ResultGallery.setScrollY: ${err}`);
    }
  }

  render() {
    return (
      <div className="search-by-name">

        {/* The outer div (above) is a relative-positioned container with a set height to save the DOM space for the form (below), which is absolutely positioned.*/}
        <div className="search-by-name-container">

          <input
            // id currently shows as error in console
            // id="search-by-name-search-box"
            className="search-by-name-input"
            placeholder="Enter a tree name..."
            onFocus={this.showSuggestionBox}
            onBlur={this.hideSuggestionBox}
            onChange={this.search}
            value={this.state.searchTerm}
            ref={this.formRef} />

          {this.state.selectedTreeId ?
            <button type="button" className="search-by-name-clear" onClick={this.clearSearch} >
              X
            </button> : ""}

          {/* The submit button that does a little bit of "fancy" when a tree is actually selected. */}
          <button
            type="submit"
            className={`search-by-name-submit label-medium ${this.state.selectedTreeId ? 'search-by-name-button-ready' : ''}`}
            onClick={this.submitForm}
          >
            {this.state.selectedTreeId ? <FaArrowRight /> : <FaSearch />}
          </button>

          {/* ALL the state is stored on this component and passed down to the children. */}
          <BtSearchByNameSuggestionBox key={this.state.reset} parentComponent={this} {...this.props} />

        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return { suggestions: state.suggestions.data }
}

export default connect(mapStateToProps, { searchByName, setSelectedTree })(withRouter(BtSearchByName));
