import React, { useEffect, useState } from 'react';
import api from "../../apis/selectree_api";
import { Container } from "react-bootstrap"
//Hooks
import { useSelector } from "react-redux";
//Components
import Map from '../Map/Map'

function BtAllMap() {

   const [positions, updatePositions] = useState([])
   const btData = useSelector(state => state.btData);

   useEffect(() => {
      async function fetchTrees() {
         const res = await api.get('/bigtrees/getAllBt');

         if(res && Array.isArray(res.data)) {
            updatePositions(res.data.filter(p=>{
               return p.latitude !== 0 && p.longitude !== 0; 
            }));         
         }
      }

      if(btData === undefined)
         fetchTrees();
      else
         updatePositions(btData.searchResults.pageResults)
   }, []);

   const satelliteView = btData && btData.searchSettings && btData.searchSettings.satelliteView?
      btData.searchSettings.satelliteView:
      false

   return <Container className="mb-5">
      <Map positions={positions} satelliteView={satelliteView}/>
   </Container>
}

export default BtAllMap
