import React, { useEffect, useState } from "react"
import { Button, Container, Form, Col, Row } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast'
import ResponsiveAccordion from '../ResponsiveAccordion/ResponsiveAccordion'
import api from '../../apis/selectree_api'
import {auth} from '../../utilities/header'
import { checkRole } from '../../utilities/user'
import NominationForm from './NominationForm'
import NotFound from "../NotFound/NotFound"
import axios from 'axios';

const ERROR_PREFIX = 'components.Nominate.EditNomination'

function EditNomination() {

   const [btInfo, setBtInfo] = useState({photos: []})
   const [state, updateState] = useState({
      validated: false,
      hasError: false,
      noMatchingTrees: false,
      loading: false,
      changed: false
   })

   const loc = window.location.href;
   const nom_id = loc.split('edit/nomination/')[1].split('?')[0];

   useEffect(()=> {
      loadNomination(nom_id);
   }, []);

   async function loadNomination(id) {
      try {
         const n = await api.get(`/bigtrees/getNomination/${id}`, auth);
         setBtInfo(n.data);
      } catch (err) {
         console.log(`${ERROR_PREFIX}.loadNomination: ${err}`);
      }
   }
   
   async function sendForm(form) {

      switch(form.owner_status) {
         case "isNominator":
            form.owner_status = true;
            form.owner_name = form.contact_name;
            form.owner_email = form.contact_email;
            form.owner_phone = form.contact_phone;
            form.owner_address = form.contact_address;
            form.owner_city = form.contact_city;
            form.owner_state = form.contact_state;
            form.owner_zip = form.contact_zip;
            break;
         case "isUnknown":
            form.owner_status = false;
            break;
         default:
            form.owner_status = true;
            break;
      }

      try {

         const {photos,...formData} = form;
         const payload = new FormData();

         let old_photos = [];

         for(let photo of (photos || [])) {
            if(photo.file !== undefined) {
               payload.append('new_photo_credits', photo.credit);
               payload.append('new_photos', photo.file);
            } else {
               old_photos.push(photo);
            }
         }

         formData.photos = old_photos;

         const json = JSON.stringify(formData);
         const blob = new Blob([json], {
            type: 'application/json'
         });

         payload.append('form', blob);

         let config = auth;
         config.headers['content-type'] = 'multipart/form-data'
         config.headers['Access-Control-Allow-Origin'] = '*'

        const nom_res = await api.put(`bigtrees/updateNom/${nom_id}`, payload, config, auth);
         return nom_res.status;
      } catch(err) {
         console.log(`${ERROR_PREFIX}.sendForm: ${err}`)
      }
   }

   const submitForm = async (event) => {
      const toastId = toast.loading('Loading...');
      try {
         sendForm(btInfo).then(status =>{
            status === 200 ? 
               toast.success('Update Successful!', {id: toastId}):
               toast.error(`Error occured with status: ${status}`, {id: toastId});
         });
      } catch(err) {
         toast.error('Oh no Update Failed o_o');
         console.log("Error during submission")
      }
   }

   const handleForm = (name, v) => {
      if(Array.isArray(btInfo[name]) && !Array.isArray(v)) {
         setBtInfo({...btInfo, [name]: [...btInfo[name], v]})
      } else {
         setBtInfo({...btInfo, [name]: v})
      }
   };

   const setAntiChampFlag = (antiChampFlag) => {
      setBtInfo({
         ...btInfo,
         [antiChampFlag]: 1,
         state_champion: 0,
         national_champion: 0,
         co_champion: 0
      });
   }

   const setChampFlag = championFlag => { 
      setBtInfo({
         ...btInfo,
         [championFlag]: 1,
         dead: 0,
         dethroned: 0,
      });
   }
   function BackToNominationButton() {
      return( 
         <div style={{ textAlign: 'center', marginBottom: '.5rem'}}>
            <a className="form-button-primary" 
               href={`${window.location.origin}/bt-tree-detail/${btInfo.bt_id}`}>Back to Tree
            </a>
         </div>)
   }

   const TreeStatus = (props) => {
      const { form } = props
      const formCheck = (v) => v === undefined || v === 0;
      return (
         <ResponsiveAccordion title="Tree Status"><Row> 
            <Col md="auto">
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="approval_status"
                        defaultChecked={form.approval_status > 0}
                        onChange={e => formCheck(form.approval_status)? 
                           handleForm("approval_status", 1):
                           handleForm("approval_status", 0)}
                        label="Verified"/> 
                  </Form.Group>
               </Container> 
            </Col> 
            <Col md="auto">
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="state-champ-status"
                        defaultChecked={form.state_champion > 0}
                        onChange={e => formCheck(form.state_champion)? 
                           setChampFlag("state_champion"):
                           handleForm("state_champion", 0)}
                        label="State Champion"/> 
                  </Form.Group>
               </Container> 
            </Col> 
            <Col md="auto">
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="national-champ-status"
                        defaultChecked={form.national_champion > 0}
                        onChange={e => formCheck(form.national_champion)? 
                           setChampFlag("national_champion"):
                           handleForm("national_champion", 0)}
                        label="National Champion"/> 
                  </Form.Group>
               </Container> 
            </Col> 
            <Col md="auto">
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="co_champion-status"
                        defaultChecked={form.co_champion > 0}
                        onChange={e => formCheck(form.co_champion)? 
                           setChampFlag("co_champion"):
                           handleForm("co_champion", 0)}
                        label="Co Champion"/> 
                  </Form.Group>
               </Container> 
            </Col>
            <Col md="auto">
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="dead-status"
                        defaultChecked={form.dead > 0}
                        onChange={e => formCheck(form.dead)? 
                           setAntiChampFlag("dead"):
                           handleForm("dead", 0)}
                        label="Dead"/> 
                  </Form.Group>
               </Container> 
            </Col>
            <Col md="auto">
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="dethroned-status"
                        defaultChecked={form.dethroned> 0}
                        onChange={e => formCheck(form.dethroned)? 
                           setAntiChampFlag("dethroned"):
                           handleForm("dethroned", 0)}
                        label="Dethroned"/> 
                  </Form.Group>
               </Container> 
            </Col> 
         </Row></ResponsiveAccordion>)
   }
   return (btInfo === "" || checkRole([2, 3]) === false? <NotFound/> :<Container>
      <h2 className="text-center category-label p-3 pt-lg-4 pb-lg-11 m-0">
         Edit Nomination #{nom_id}
      </h2>
      <BackToNominationButton />
      <Form noValidate>
         <TreeStatus form={btInfo}/>
         <br />
         <NominationForm form={btInfo} handleForm={handleForm} hasError={state.hasError} />
         <br/>
         <div className="col-md-12 text-center">
            <Button onClick={submitForm}>Update Nomination</Button>
         </div>
         <br/>
      </Form>
      <Toaster/>
   </Container>)
}

export default EditNomination
