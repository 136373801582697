function convertToCSV(arr) {
   const array = [Object.keys(arr[0])].concat(arr)

   return array.map(it => {
      var values = Object.values(it);
      for(let i in values) {
         if(typeof values[i] === 'string' || values[i] instanceof String) {
            values[i] = '"' + values[i] + '"';
         }
      }
      return values.toString();
   }).join('\n')
}

export function downloadCSV(data, name) {
   if(Array.isArray(data) && data.length > 0) {
      const csv_data = convertToCSV(data);
      // Creating a Blob for having a csv file format
      // and passing the data with type 
      
      const blob = new Blob([csv_data], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob)

      // Creating an anchor(a) tag of HTML
      const a = document.createElement('a')

      // Passing the blob downloading url
      a.setAttribute('href', url)

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + '-' + dd + '-' + yyyy;
      a.setAttribute('download', name + '-' + today +'.csv');

      // Performing a download with click
      a.click();
   }
}
