import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import {Link} from 'react-router-dom'

import api from "../../apis/selectree_api";
import { auth } from "../../utilities/header"

import FormFieldPlainText from '../FormField/FormFieldPlainText'
import ResponsiveAccordion from '../ResponsiveAccordion/ResponsiveAccordion';

class UserAccountInvite extends React.Component {

    constructor(props) {
        super();

        this.state = {
            email: ""
        }
    }

    updateUser = async () => {
        let email = this.state.email

        try {
            if (email) {
                let res = await api.post(`/users/invite`, {email}, auth);
                this.setState({error: '', success: "Invitation sent."})
                //success message
                console.log(res)
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            this.setState({succcess: '', error: "Could not send invite. Please double check the email address."})
            console.log(`Error in components.UserAccountInvite.updateUser: ${err}`);
        }
    }

    render() {
        return (
            <div className="container pt-4 pb-4" >
                <h1 className="mb-2">Invite New User</h1>


                <Link to="/account" className="form-button-secondary mb-2" >Back</Link>

                <div className="mb-4">
                    <ResponsiveAccordion title="USER INFORMATION">
                        <Container>
                            <Row>
                                
                                <Col lg={12}>
                                    <FormFieldPlainText
                                        title="Email"
                                        field="email"
                                        parentComponent={this}
                                    />
                                </Col>

                                <Col lg={12}>
                                    <span onClick={this.updateUser} className="form-button-primary"> Submit </span>&nbsp;&nbsp;&nbsp;
                                    {this.state.error ? <p>{this.state.error}</p> : "" }
                                    {this.state.success ? <p>{this.state.success}</p> : "" }
                                </Col>



                            </Row>
                        </Container>
                    </ResponsiveAccordion>
                </div>

            </div>
        )
    }
}

export default UserAccountInvite;