import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import './BigTree.css'
// components
import QuickLinks from "../QuickLinks/QuickLinks";
import BtResult from "../BtResult/BtResult";
// hooks
import { useDispatch, useSelector } from "react-redux";
// api
import api from "../../apis/selectree_api";



const BigTree = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            const response = await api.get(`/bigtrees/count`);
            const btData = response.data
            dispatch({ type: "SET_COUNT", payload: btData });
        }
        fetchData();

    }, [])

    const btData = useSelector(state => state.btData);
    return (
        <>

            <div className="bigtree d-flex flex-wrap">

                <hr className="d-lg-none bigtree-mobile-hr" />

                <Container fluid>
                    <div className="d-lg-none">
                        <QuickLinks />
                    </div>
                    <div className="bigtree-top-part bg-light">
                        <Container>
                            <Row className="bigtree-row-container">
                                <Col xs={12} md={6} lg={7} className="home-stats">
                                    <div>
                                        <br />
                                        <h5>California has:</h5>
                                        <h2>{btData.btCount} Big Tree Listings <span className="hs-small">and</span> </h2>
                                        <h2>{btData.champCount} National Champions</h2>


                                        <p>Questions? Email mritter@calpoly.edu</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>

                    <Container>
                        <hr className="mt-5 mb-5" />
                        <BtResult />
                    </Container>


                </Container>
            </div>
        </>
    )
};

export default BigTree;
