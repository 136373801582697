import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import NotFound from "../NotFound/NotFound"
// import { getBtDetail } from '../../actions/btTreeDetail';
import api from "../../apis/selectree_api";
import { auth } from "../../utilities/header"
import './BtTreeDetail.css';
import { checkRole } from '../../utilities/user'

// components
import TreeNameInfo from "../TreeNameInfo/TreeNameInfo";
import ImageGallery from "../BtTreeDetailGallery/TreeDetailGallery";
import Map from "../Map/Map"

const BtTreeDetail = (props) => {
   const loc = window.location.href;
   const id = loc.split('bt-tree-detail/')[1];
   const dispatch = useDispatch();

   const btData = useSelector(state => state.btData);
   const searchSettings = btData.searchSettings;

   useEffect(() => {
      async function fetchDetail() {
         const response = await api.get(`/bigtrees/getBtDetail/${id}`, auth);
         return response.data;
      }

      fetchDetail()
         .then(data => dispatch({ type: "SET_BT_DETAIL", payload: data }))
   }, []);

   const formatDateCreated = (date) => {
      if (!date || date === '0000-00-00') {
         return '. ';
      } else {
         let year = date.split('-')[0];
         return ` in ${year}. `;
      }
   };

   const renderBtMemo = () => {
      let location = btData.btDetail.btInfo.location;
      let nominators = btData.btDetail.btInfo.nominator_names; 
      let date_created = formatDateCreated(btData.btDetail.btInfo.date_created);
      let height = btData.btDetail.btInfo.height;
      let girth = btData.btDetail.btInfo.girth;
      let crown_width = btData.btDetail.btInfo.crown_width;
      let totalPoints = btData.btDetail.btInfo.points;

      let memo = `This tree, in ${location}, was nominated by ${nominators}${date_created} It measures ${height} feet high, with a trunk circumference of ${girth} inches and a crown spread of ${crown_width} feet for a total of ${totalPoints} points.`;

      return memo;
   }

   function EditButton() {
      return checkRole([2, 3]) ?
         <div className='d-flex justify-content-center'>
            <a className="form-button-primary" 
               href={`${window.location.origin}/edit/nomination/${btData.btDetail.btInfo.nom_id}`}>Edit Nomination
            </a>
         </div> : ""
   }

   function championText() {
      const btInfo = btData.btDetail.btInfo;

      const co_champ = btInfo.co_champion === 1? 'Co-': '';

      if(btInfo.national_champion === 1)
      return `National ${co_champ}Champion`
      else if (btInfo.state_champion)
      return `State ${co_champ}Champion`
      return '';
   }

   function toggleMapView() {
      dispatch({
         type: "SET_SEARCH_SETTINGS", 
         payload: {
            ...searchSettings, 
            satelliteView: !searchSettings.satelliteView 
         }
      });
   }

   if (btData.btDetail) {
      return (btData.btDetail.btInfo === undefined? <NotFound/> :
         <>
            <Container fluid className="tree-detail--tree-name-info">
               <TreeNameInfo btData={btData} />
            </Container>

            <Container fluid className="tree-detail--tree-name-info">
               <Container className='home-stats'>
                  <Row>
                     <Col lg={4}>
                        <h2 style={{display: "inline", whiteSpace: "nowrap"}}>
                           {championText() }
                        </h2>
                     </Col>
                     <Col lg={4}>
                        <EditButton />
                     </Col>
                     <Col lg={4}>
                        <h2 className='point_score'>
                           {btData.btDetail.btInfo.points} pts 
                        </h2>
                     </Col>
                  </Row>
                  <p><i>{renderBtMemo()}</i></p>
               </Container>
            </Container>

            <Container fluid className="tree-detail--tree-detail-gallery ">
               <Container>
                  <ImageGallery images={btData.btDetail.photoLinks}/>
               </Container>
            </Container>

            {btData.btDetail.btInfo.latitude !== 0 & btData.btDetail.btInfo.longitude !== 0?
               <Container>
                  <hr style={{padding: "0rem 0rem 1rem 0rem"}}className="mt-5 mb-5" />

                     <h2 style={{marginBottom: '1rem'}}>
                        Tree Map
                        <Button style={{marginLeft: '2rem'}} onClick={()=> toggleMapView()}>
                           {btData.searchSettings.satelliteView? 'Normal View': 'Satellite View'}
                        </Button>
                     </h2>

                  <Container  className="mb-5">
                     <Map 
                        positions={[btData.btDetail.btInfo]} 
                        zoom={11} 
                        satelliteView={searchSettings.satelliteView} />
                  </Container>
                  {/* {this.state.noMatchingTrees ? <ErrorPage message={"There are no trees found that match that query."} /> : */}

               </Container>: ""}
            </>
      )
   } else {
      return (
         <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>
      )
   }

}


export default BtTreeDetail;
