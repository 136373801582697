import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import htmlParser from 'react-html-parser';
import './TreeNameInfo.css';
import uuid from 'react-uuid';

const TreeNameInfo = (props) => {
    const btDetail = props.btData.btDetail;
    const btInfo = btDetail.btInfo;
    const btSynonyms = btDetail.btSynonyms;
    const btCommons = btDetail.btAdditionalCommons;
    const renderScientificName = () => {
        try {
            if (btInfo) {
                return (
                    <span>{htmlParser(btInfo.name_concat)}</span>
                )
            } else {
                throw new Error(`missing scientific name`);
            }
        } catch (err) {
            console.log(`components.TreeNameInfo.renderScientificName: ${err}`);
            return null;
        }
    }

    const renderSynonyms = () => {
        if (btSynonyms) {
            return (
                <Col xs={{ span: 12 }} md={4} lg={4} className="less-line-spacing tree-name-info-middle-text">
                    <h4 className="label-demibold">SYNONYMS</h4>
                    {btSynonyms.map(s => {
                        return (<p className="font-italic" key={uuid()}>{htmlParser(s.name_concat)}</p>);
                    })}
                </Col>
            );
        } else {
            return (
                <Col xs={{ span: 12 }} md={4} lg={4} className="less-line-spacing tree-name-info-middle-text">
                    <h4 className="label-demibold">SYNONYMS</h4>
                </Col>
            );
        }
    }

    const renderCommons = () => {
        if (btCommons) {
            return (
                <Col xs={{ span: 12 }} md={4} lg={4} className="less-line-spacing tree-name-info-right-text">
                    <h4 className="label-demibold">ADDITIONAL COMMON NAMES</h4>
                    {btCommons.map(s => {
                        return (<p key={uuid()}>{s}</p>)
                    })}
                </Col>
            )
        }

    }

   function SpeciesLink() {
      return <span>
         <a 
            className="form-button-primary"
            onClick={()=> {
                  window.open(`https://selectree.calpoly.edu/tree-detail/${btInfo.tree_id}`)
               }}>See Species
         </a>
      </span>
   }

   if (btInfo) {
      return (
         <Container className="tree-name-info">
            <Row>
               <Col xs={{ span: 12 }}>
                  <Row className="tree-name-info-row">
                     <Col xs={{ span: 12 }} md={4} className="less-line-spacing tree-name-info-left-text">
                        <h2 className="label-demibold">{btInfo.common ? btInfo.common : null}</h2>
                        <p className="font-italic text-lg tree-name-info-science-name">
                           {renderScientificName()}
                        </p>
                        <h4 className="label-demibold">FAMILY <span className="tree-name-info-family font-weight-normal">{btInfo.family ? btInfo.family : null}
                        </span>
                        </h4>
                        <SpeciesLink />
                     </Col>
                     {renderSynonyms()}
                     {renderCommons()}
                  </Row>
               </Col>
            </Row>
         </Container>
      )
   } else {
      console.log(`components.TreeNameInfo: treeDetail empty`);
      return null;
   }

}

export default TreeNameInfo;
