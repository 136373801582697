import React from 'react';
import { Container } from 'react-bootstrap';
import './BigTreeHeader.css';
// components
import BigTreeBanner from "../BigTreeBanner/BigTreeBanner";
import QuickLinks from "../QuickLinks/QuickLinks";
import BigTreeNavbar from '../BigTreeNavbar/BigTreeNavbar';

const BigTreeHeader = (props) => {
    return (
        <Container style={{ position: 'relative', zIndex: '10' }} fluid>
            <BigTreeNavbar/>
            <BigTreeBanner />
            <div className="d-none d-lg-block">
                <QuickLinks />
            </div>
        </Container>

    )
}

export default BigTreeHeader
