import React, { useEffect, useState } from "react";
import { FaCaretSquareUp, FaCaretSquareDown, FaCheckCircle, FaTimesCircle, FaTrashAlt} from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast'
import {auth} from '../../utilities/header'
import { Button, Table, Col } from 'react-bootstrap';
import TreeSelect from '../FormField/TreeSelect'
import ResponsiveAccordion from '../ResponsiveAccordion/ResponsiveAccordion'
import { downloadCSV } from '../../utilities/csv'
import api from '../../apis/selectree_api';
import './NominationList.css';

const ERROR_PREFIX = 'components.Nominate.NominationList'

function NominationList() {
   const [nominations, updateNominations] = useState([])
   const [filters, updateFilters] = useState({})

   useEffect(()=> {
      loadNominations();
   }, []);

   async function loadNominations() {
      try {
         const noms = (await api.get('bigtrees/getNominationList', auth)).data;
         updateNominations(noms)
      } catch(err) {
         console.log(`${ERROR_PREFIX}.loadNominations: ${err}`)
      }
   }

   async function deleteNomination(nom_id, idx) {
      try {
         const res = await api.delete(`bigtrees/nomination/delete/${nom_id}`, auth);
         if(res.status === 204) {
            var new_noms = [];
            for(let n of nominations) {
               if(n.nom_id !== nom_id)
               new_noms.push(n);
            }
            updateNominations(new_noms);
         };

      } catch(err) {
         console.log(`${ERROR_PREFIX}.deleteNomination: ${err}`) 
      }
   }

   function filter(name, v) {
      updateFilters(prev => {
         return {...prev, [name]: v};
      })
   }

   function nominationRow(nom, idx) {
      return (
         <tr className={idx % 2 === 0?"tr-even":"tr-odd"} key={idx}>
            <td/>
            <td><a href={`${window.location.origin}/edit/nomination/${nom.nom_id}`}>
               <u>{nom.nom_id}</u></a>
            </td>
            <td>{nom.approval_status > 0?
               <FaCheckCircle color="#457537"/>:
               <FaTimesCircle color="red"/>}
            </td>
            <td>{nom.family}</td>
            <td>{nom.common}</td>
            <td>{nom.scientific_name}</td>
            <td>{nom.nominator_names}</td>
            <td>{nom.circumference}</td>
            <td>{nom.height}</td>
            <td>{nom.crown_width}</td>
            <td>{nom.tree_county}</td>
            <td>{nom.date_measured}</td>
            <td>
               <FaTrashAlt onClick={() => { 
                  const res = window.confirm("Are you sure you want to delete this nomination?")
                  if(res === true)
                     deleteNomination(nom.nom_id, idx)
               }}/>
            </td>
         </tr>
      )
   }

   function SortableHeader(props) {
      const {label, name} = props
      return (<th key={label}>
         <div>
            <FaCaretSquareUp onClick={(e) => sortNominatons(name, true)}/>
            <FaCaretSquareDown onClick={(e) => sortNominatons(name, false)}/>
         </div>
         <span>{label}</span>
      </th>)
   }

   function sortNominatons(criteria, acc) {
      const noms = nominations.sort((a, b) => {
         if(acc)
            return a[criteria] > b[criteria]
         
         return a[criteria] < b[criteria]
      })

      updateNominations([...noms])
   }

   async function getCSV() {
      try {
         const res = await api.get('bigtrees/dataDump', auth);
         res.status === 200 ?
            downloadCSV(res.data[0], "BigTreeDataDump"):
            toast.error(`Error ${res.status} occured while Downloading`);

      } catch (err) {
         toast.error('Error Occured while Downloading')
      }
   }

   return(
      <div> 
         <h2 className="text-center category-label p-3 pt-lg-4 pb-lg-11 m-0">Nominations</h2>
         <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Filters">
               <Col xs={12} lg={12}>
                  <Button 
                     type="reset" 
                     onClick={()=> updateFilters({}) }>Clear Filters</Button>
                  <br/><br/>
                  <TreeSelect 
                     label="Tree Name"
                     name="tree_id"
                     onChange={filter}
                     />
                     <br/>
               </Col>
            </ResponsiveAccordion>
         </Col>
         <div className="col-md-12 text-center">
            <Button 
               type="reset" 
               onClick={() => getCSV()}>
               Download CSV
            </Button>
         </div>
         <br/>
         <Table className="NominationList text-center">
            <thead>
               <tr>
                  <th></th>
                  <th>Link</th>
                  <SortableHeader label="Verified" name="approval_status"/>
                  <SortableHeader label="Family" name="family"/>
                  <SortableHeader label="Common Name" name="common"/>
                  <SortableHeader label="Scientific Name" name="scientific_name"/>
                  <SortableHeader label="Nominator(s)" name="nominator_names"/>
                  <SortableHeader label="Circumference" name="circumference"/>
                  <SortableHeader label="Height" name="height"/>
                  <SortableHeader label="Crown Width" name="crown_width"/>
                  <SortableHeader label="County" name="tree_county"/>
                  <SortableHeader label="Date Measured" name="date_measured"/>
               </tr>
            </thead>
            <tbody>
               {nominations.filter(nom => filters.tree_id !== undefined? nom.tree_id === filters.tree_id: true).map((nom, idx)=> {
                  return nominationRow(nom, idx)
               })}
            </tbody>
         </Table>
         <Toaster/>
      </div>
   )
}

export default NominationList;
