import React from "react";
import "./QuickLinks.css";
import { Container, Row, Col } from 'react-bootstrap'
import { FaSearch, FaQuestion } from "react-icons/fa";

class QuickLinks extends React.Component {

  quickLinkButton = (title, paragraph, Icon, link) => {
    return <a href={link} className="quicklinks-link p-2">

      <span href="#" className="btn-circle-md bg-primary text-secondary quicklinks-link-icon d-inline-block">
        <Icon className="btn-icon" />
      </span>

      <p className="quicklinks-link-text d-inline-block m-0">
        <strong>{title}</strong><br />
        {paragraph}
      </p>

    </a>
  }

  render() {
    return (
      <div className="quicklinks-bg">
        <Container className="quicklinks-container">
          <Row>
            <Col md={4} xs={6}>
              {this.quickLinkButton("NOMINATE A TREE", "Where you can submit your own tree.", FaSearch, "/nominate")}
              {/*{this.quickLinkButton("NOMINATE A TREE", "Coming soon.", FaSearch, "/nominate")}*/}
            </Col>

            {/* This one is hidden on sm & xs views */}
            <Col md={4} className="d-none d-md-block">
              {this.quickLinkButton("HOW TO MEASURE A TREE", "Instruction for accurate measurements.", FaSearch, "/how-to-measure-a-tree")}
            </Col>
            
            <Col md={4} xs={6}>
              {this.quickLinkButton("ALL TREES", "See all trees in the database.", FaQuestion, "/")}
            </Col>
          </Row>

        </Container>
        </div>
    );
  }
}

export default QuickLinks;
