import React from 'react';
import './Help.css';

class SearchHelp extends React.Component {
    constructor(props) {
        super();

    }

    render() {
        return (
            <div className="search-help container">
                 <div class="row">
                    <div class="col-md-12">
                        <h1>
                            California Big Trees - Help
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h2 id="coordinates">How do I get coordinates?</h2>
                        <h5>Desktop computers</h5>
                        <ol>
                            <li>Use <a href="https://www.google.com/maps/" rel="noopener">Google Maps <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a> in a web browser to find your tree's location.</li>
                            <li>Mark the tree's location on the map by right-clicking or ctrl-clicking the location.</li>
                            <li>From the contextual menu, select <strong>What's here?</strong> An info card with coordinates will appear.</li>
                            <li>Copy the entire latitude and longitude string of numbers, and paste it into the Map Coordinates field in the Nomination Form.</li>
                            <li><strong>Note:</strong> make sure Google Maps is not in <a href="https://support.google.com/maps/answer/3031966?hl=en" rel="noopener">Lite mode <span class="glyphicon glyphicon-new-window" aria-hidden="true"></span></a>. If you use Google Maps in Lite mode, you won't be able to get the coordinates of a place.</li>
                        </ol>

                        <h5>Mobile and tablets</h5>
                        <ol>
                            <li>Use the Google Maps app to find your tree's location.</li>
                            <li>Mark the tree's location on the map by touching and holding the location, until a marker appears. The coordinates should appear in the search box at the top of the display.</li>
                            <li>Copy the entire latitude and longitude string of numbers, and paste it in the Map Coordinates field in the Nomination Form.</li>
                        </ol>

                        <h2 id="another">Another question?</h2>
                        <p>Questions regarding California Big Trees can be directed to Matt Ritter at <a href="mailto:mritter@calpoly.edu">mritter@calpoly.edu</a>.</p>
                    </div>
                </div>

            </div>
        )

    }
}

export default SearchHelp