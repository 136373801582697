import React, { useState, useEffect } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import "./BtResult.css";
import qs from 'query-string';
import api from "../../apis/selectree_api";
// hooks
import {useSelector, useDispatch } from "react-redux";
// components
import BtResultOps from "../BtResultOps/BtResultOps";
import BtResultGallery from '../BtResultGallery/BtResultGallery';
import BtAllMap from "../BtAllMap/BtAllMap"
import { useSaveState, useLoadState} from '../../utilities/sessionStorage';

const BtResult = () => {
   const dispatch = useDispatch();  
   const params = qs.parse(window.location.search);
   const searchTerm = params.searchTerm || false;
   const loadState = useLoadState();
   
   useSaveState(searchTerm);

   useEffect(() => {
      if(!loadState(searchTerm)) {
         initSearchSettings();
         fetchTrees();
      } 
   }, [])

   function initSearchSettings() {
      try {
         dispatch({
            type: "SET_SEARCH_SETTINGS", 
            payload: {
               activePage: parseInt(params.activePage) || 1,
               resultsPerPage: parseInt(params.resultsPerPage) || 12,
               sort: parseInt(params.sort) || 1,
               mapToggle: false,
               satelliteView: false
            }})
      } catch(err) {
         console.log(`components.BtResult.initSearchSettings: ${err}`);
      }
   }

   const fetchTrees = async () => {
      try {
      if (searchTerm) {
         const res = await api.get(`/bigtrees/getBtSearchResults?searchTerm=${searchTerm}`);

         const searchResults = res.data

         if (searchResults.length === 1) {
            window.location.href = `/bt-tree-detail/${searchResults[0].bt_id}`;
         }

         dispatch({ type: "SET_SEARCH_RESULTS", payload: {
            pageResults: searchResults, 
            totalResults: searchResults.length,
         }});

      } else {
         const res = await api.get(`/bigtrees/getAllBt`);
         const searchResults = res.data
         dispatch({ type: "SET_SEARCH_RESULTS", payload: {
            pageResults: searchResults, 
            totalResults: searchResults.length,
         }});   
      }
      } catch (err) {
         console.log(`components.BtResult.fetchTrees: ${err}`);
      }
   }

   const btData = useSelector(state => state.btData);

   if (btData.searchResults) {
      let searchSettings = btData.searchSettings;

      return <Container fluid className="result">
         <BtResultOps />
         {searchSettings.mapToggle? <BtAllMap/> : <BtResultGallery />}
      </Container >
   } else {
      return <Container>
         <Spinner />
      </Container>
   }
}

export default BtResult;
