import React, { useState } from 'react'
import { Row, Form, Container, Button } from 'react-bootstrap'
import { FaTrashAlt, FaPencilAlt} from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast'
import TextInput from './TextInput'
import './FormField.css'

function removeItem(arr, idx) {
   var res = [];
   for(let i = 0; i < arr.length; i++) {
      if(i !== idx)
         res.push(arr[i]);
   }
   return res;
}

function PreviewPhoto(props) {
   const [editModal, updateEditModal] = useState(false);
   const [pictureModal, updatePictureModal] = useState(false);
   const [selected, updateSelection] = useState(true);

   props.photo.selected = selected;

   const {idx, photos, photo, handleForm} = props;

   return (<div className='tree-form--individual-photo' >
      <div className="tree-form--individual-photo--image"
         onClick={()=> updatePictureModal(!pictureModal)}>
         <img 
            src={photo.preview === undefined? 
               `${process.env.REACT_APP_BASE_IMAGE_URL}${photo.link}`:
               photo.preview }
            className="img-fluid"
            />
      </div>
      <span className="photo-check">
         <input
            type="checkbox" 
            className="big-checkbox"
            defaultChecked={selected}
            onChange={() => {
               handleForm("photos", 
                        [...photos.slice(0, idx),
                        {...photo, selected: !selected },
                         ...photos.slice(idx + 1)]);
               updateSelection(!selected);
            }}
            />
      </span>
      <span className="photo-edit"
         onClick={()=> updateEditModal(!editModal)}>
         <div>
            <FaPencilAlt />
         </div>
      </span>
      <span className="photo-delete" 
         onClick={()=> handleForm("photos", removeItem(photos, idx))}>
         <FaTrashAlt />
      </span>
      {editModal? 
         <div id='photo-modal-background'>
            <div className='photo-modal-main'>
               <span className="photo-modal-close btn btn-primary" 
                  onClick={() => updateEditModal(!editModal)}>X</span>
               <h2>Edit Photo</h2>
               <TextInput 
                  label="Filename" 
                  name="name"
                  defaultValue={photo.name !== undefined? photo.name: photo.file.name}
                  onChange={ (n, v) => {
                     handleForm("photos",
                        [...photos.slice(0, idx),
                        {...photo, name: v },
                         ...photos.slice(idx + 1)])
                  }}/>
               <TextInput
                  label="Credit"
                  name="credit" 
                  defaultValue={photo.credit !== undefined? photo.credit: photo.file.credit}
                  onChange={ (n, v) => {
                     handleForm("photos",
                        [...photos.slice(0, idx),
                        {...photo, credit: v },
                         ...photos.slice(idx + 1)])
                  }}/>
            </div>
         </div>
         : ""}
      {pictureModal ?
         <div id='modal-background'>
            <div className='photo-modal-main'>
            <span className="photo-modal-close btn btn-primary" 
               onClick={() => updatePictureModal(!pictureModal)}>X</span>
               <img 
                  src={photo.preview === undefined? 
                     `${process.env.REACT_APP_BASE_IMAGE_URL}${photo.link}`:
                     photo.preview }
            />
            </div>
         </div>
         : ""}
   </div>)
}

function PhotoGallery(props) {
   const { photos, handleForm} = props;
   return (<Container><Row>
      {Array.isArray(photos)? photos.map((path, i) => <PreviewPhoto
         key={i}
         idx={i}
         photo={path}
         photos={photos}
         handleForm={handleForm}/>): ""}
   </Row></Container>)
}

function BulkPhotoUpdater(props) {
   const {photos, bulkUpdater} = props;
   const [values, updateValues] = useState({})

   return (<span>
      <h4>Update Selected Photos</h4>
      <TextInput
         label="Photo Credits"
         name="credit" 
         onChange={(n, v) => updateValues({...values, credit: v})}/>
      <Button 
         onClick={() => bulkUpdater(photos, values)}>
         Update Photo Credits
      </Button>
   </span>)
}

function PhotoInput (props) {
   const {handleForm, form} = props;
   
   function readFile(file) {
      return new Promise((resolve, reject) => {
         var fr = new FileReader();  
         fr.onload = () => {
            resolve({file: file, preview: fr.result})
         };
         fr.onerror = reject;
         fr.readAsDataURL(file);
      });
   }

   function loadImages(files) {
      const imgs = [];

      for(let file of files)
         imgs.push(readFile(file));

      Promise.all(imgs).then((values) =>{
         handleForm("photos", [...form.photos, ...values]);
      });
   }

   function bulkUpdater(photos, values) {
      const updatedImgs = [];

      if(Array.isArray(photos) && values !== undefined) {
         for(let photo of photos) {
            photo.selected !== undefined && photo.selected?
               updatedImgs.push({
                  ...photo, 
                  credit: values.credit ? values.credit: ""
               }):
               updatedImgs.push(photo);
         }
      }

      handleForm("photos", updatedImgs);

      toast.success('Photos Credits Updated!')
   }

   return(<Form.Group> 
      <input 
         className='photoInput'
         type="file"
         accept="image/jpeg, image/png"
         required
         multiple
         id='photoUploader' 
         name="fileName"
         minLength={3}
         edited={Array.isArray(form.photos) && form.photos.length < 3? "true": "false"}
         onChange={(e) => loadImages(e.target.files)} 
         />
      <PhotoGallery photos={form.photos} handleForm={handleForm}/>
      <div className='error-message'>Must have 3 images</div>
      <br/>
      <BulkPhotoUpdater photos={form.photos} bulkUpdater={bulkUpdater}/>
      <Toaster />
   </Form.Group>)
}

export default PhotoInput;
