import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import api from "../../../apis/selectree_api";
import { auth } from "../../../utilities/header"

const arrayEquality = (a1, a2) => {
    var i = a1.length;
    while (i--) {
        if (a1[i]?.link !== a2[i]?.link) return false;
    }
    return true
}


class ReorderPhotos extends React.Component {

    constructor(props) {
        super();
        this.state = {
            images: [],
            modalOpen: false,
            prevImages: [],
            saving: false,
            changed: false
        }
    }


    static getDerivedStateFromProps(props, state) {
        let newValue = props.parentComponent.state.images

        let imagesEqual = arrayEquality(newValue, state.prevImages)

        if (!imagesEqual) {
            return { images: newValue, prevImages: state.images }
        } else { return {} }
    }

    toggleModal = () => {
        if (this.state.modalOpen && this.state.changed) {
            if (window.confirm("Are you sure you wish to leave? Order will not be saved.")) {
                this.setState({ modalOpen: !this.state.modalOpen })
            }
        } else {
            this.setState({ modalOpen: !this.state.modalOpen })
        }
    }

    updatePhotos = async () => {
        let photoData = this.state.images.map((image) => ({ photo_link_id: image.photo_link_id, sequence: image.sequence }))
        this.setState({ saving: true })

        try {
            if (photoData) {
                await api.put(`/photo/batch-update`, photoData, auth);
                window.setTimeout( () => {
                    this.props.parentComponent.setState({ forceData: true }, this.setState({ saving: false, changed: false }))
                }, 500) 
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.actions.photo: ${err}`);
        }
    }

    onDragEnd = (e) => {
        // the only one that is required
        let sourcePos = e.source.index + 1
        let destinationPos = e.destination.index + 1

        let images = this.state.images

        if (sourcePos > destinationPos) {
            images.map((image) => {
                let sequence = image.sequence
                if (sequence >= destinationPos && sequence < sourcePos) {
                    image.sequence = sequence + 1
                }
                if (sequence === sourcePos) {
                    image.sequence = destinationPos
                }
                return image
            })
        } else if (destinationPos > sourcePos) {
            images.map((image) => {
                let sequence = image.sequence
                if (sequence > sourcePos && sequence <= destinationPos) {
                    image.sequence = sequence - 1
                }
                if (sequence === sourcePos) {
                    image.sequence = destinationPos
                }
                return image
            })

        }

        this.setState({ images, changed: true })

    };

    
    errorLoadingImage = (e) => {
        let img = e.target
        img.onerror = null;
        img.src = "/images/broken-link.png";
    }


    render() {
        return this.state.modalOpen ?
            <div id="photo-modal-background" onMouseDown={(e) => { if (e.target === e.currentTarget) { this.toggleModal() } }}>
                {/* Make sure to close the modal on a background click ^^ */}
                <div className="photo-modal-main">
                    {/* The main "close" button. */}
                    <span className="photo-modal-close btn btn-primary" onClick={this.toggleModal}>X</span>


                    <h2>Arrange Photos</h2>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div className="reorder-photos-drop-area">
                            <Droppable droppableId="photo-drop-area" direction="horizontal">
                                {(provided) =>
                                    <div ref={provided.innerRef}  {...provided.droppableProps}  >
                                        {this.state.images.sort((a, b) => a.sequence - b.sequence).map((photo, i) =>
                                            <Draggable className="tree-form--individual-photo" key={`${i}-${photo.link}`} draggableId={`draggable-${i}`} index={i} >
                                                {(provided) =>
                                                    <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}>

                                                        <div className="tree-form--reorder-photo--image">
                                                            <img alt="" src={photo.link} width="150px" style={{ padding: '10px' }} onError={this.errorLoadingImage}  />
                                                            sequence: {photo.sequence}
                                                        </div>

                                                    </div>
                                                }
                                            </Draggable>
                                        )}

                                        {provided.placeholder}
                                    </div>
                                }
                            </Droppable>
                        </div>
                    </DragDropContext>

                    <span onClick={this.toggleModal} className="form-button-secondary" style={{ marginTop: "25px" }}> Cancel </span>&nbsp;&nbsp;&nbsp;
                    <span onClick={this.updatePhotos} className={this.state.saving ? "form-button-secondary" : "form-button-primary"}>
                        {this.state.saving ? "Saving...." : "Save"}
                    </span>


                </div>
            </div> :
            <div>
                <span className="form-button-primary" onClick={this.toggleModal}>Arrange Photos</span>
            </div>
    }

}

export default ReorderPhotos
