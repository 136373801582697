import React from "react";
import { connect } from 'react-redux';
import { Row, Col, Container, Form } from "react-bootstrap";
import axios from 'axios';
import { withRouter } from 'react-router'

import api from "../../../apis/selectree_api";
import { auth } from "../../../utilities/header"

import { FaTrashAlt } from "react-icons/fa";

import PhotoModal from './PhotoModal'
import ReorderPhotos from './ReorderPhotos'



class Photos extends React.Component {

    constructor(props) {
        super();
        this.state = {
            images: [],
            imageFiles: null,
            credits: "M. Ritter and J. Reimer",
            bulkCredit: "",
            bulkSaving: false,
            checkedPhotos: []
        }
    }

    componentDidMount = async () => {
        this.getPhotos()
    }

    componentDidUpdate = async () => {
        if (this.state.forceData) {
            this.getPhotos()
        }
    }

    getPhotos = async () => {
        let query = this.props.match.params.treeName;

        if (Number.parseInt(query)) {
            let treeId = Number.parseInt(query);

            try {
                if (treeId) {
                    let response = await api.get(`/photos/${treeId}`);
                    this.setState({ images: response.data, forceData: false, bulkSaving: false })
                } else {
                    throw new Error('no id provided');
                }
            } catch (err) {
                console.log(`Error in components.TreeFormCard.EditPhotos.Photos.getPhotos: ${err}`);
            }
        }
    }

    deletePhoto = async (e) => {
        ///GRAB ACTUAL PHOTO ID FROM ELEMENT
        let photoId = e.currentTarget.getAttribute('data-photo-id')
        try {
            if (photoId) {
                if (window.confirm("Are you sure you wish to delete this photo? There is no undoing this action.")) {
                    await api.delete(`/photo/delete/${photoId}`, auth);
                    this.setState({ forceData: true })
                }
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.actions.photo: ${err}`);
        }
    }

    stageImageFiles = (e) => {
        try {
            const files = e.target.files;
            this.setState({ imageFiles: files });
        } catch (err) {
            console.log(`components.TreeFormCard.Photos.stageImageFiles: ${err}`);
        }


    }

    uploadImageFiles = async () => {
        if (this.state.imageFiles.length > 0) {
            this.setState({ saving: true })

            const imageFiles = Array.from(this.state.imageFiles);

            let imagePromises = imageFiles.map((file, i) => {
                var fileData = file.name.split('.');
                var treeId = this.props.treeDetail.treeId;
                var fileName = fileData[0];
                var fileType = fileData[1];
                var url = `/api/photo/getSignedS3?treeId=${treeId}&fileName=${fileName}&fileType=${fileType}&number=${i}&credits=${this.state.credits}`

                // get the signed URLs for the upload
                return axios.get(url)
                    .then(response => {
                        var data = response.data.data;
                        console.log(`components.TreeFormCard.Photos.uploadImageFiles: received signed url: ${data.signedRequestUrl}`);
                        var options = {
                            headers: {
                                'Content-Type': fileType
                            }
                        };
                        // now do the upload
                        return axios.put(data.signedRequestUrl, file, options)
                            .then(result => {

                                let photoLink = data.photoLink
                                let createUrl =  `/api/photo/create`
                                let createData = {treeId, photoLink, number: i}

                                return axios.post(createUrl, createData, auth) 
                                .then(() => {
                                    console.log(`components.TreeFormCard.Photos.uploadImageFiles: ${file.name} complete upload successful`);
                                })
                                .catch(err => {
                                    console.log(`components.TreeFormCard.Photos.uploadImageFiles: error creating in database: ${err}`);
                                })
                            })
                            .catch(err => {
                                console.log(`components.TreeFormCard.Photos.uploadImageFiles: error uploading to S3: ${err}`);
                            })

                    })
                    .catch(err => {
                        console.log(`components.TreeFormCard.Photos.uploadImageFiles: error requesting signed S3 from server: ${err}`);
                    });
            });

            //Wait for all promises to resolve and then reload images, and clear what's in state and in form
            Promise.allSettled(imagePromises)
                .then(() => {
                    this.setState({ forceData: true, imageFiles: null, saving: false });
                    document.getElementById("photoUploader").value = "";
                })
                .catch((err) => {
                    console.log(`components.TreeFormCard.Photos.uploadImageFiles: error: ${err}`);
                })
        }
    }

    errorLoadingImage = (e) => {
        let img = e.target
        img.onerror = null;
        img.src = "/images/broken-link.png";
    }

    changeCredits = (e) => {
        let credits = e.target.value
        this.setState({ credits })
    }

    bulkEdit = async () => {

        this.setState({ bulkSaving: true })

        try {
            let response = await api.put(`/photo/bulk-update-credit`,
                { photo_ids: this.state.checkedPhotos, credit: this.state.bulkCredit },
                auth );

            this.setState({ forceData: true, bulkCredit: "", checkedPhotos: [] })


        } catch (err) {
            console.log(`Error in components.TreeFormCard.EditPhotos.Photos.getPhotos: ${err}`);
        }

    }

    changeBulkCredit = (e) => {
        let bulkCredit = e.target.value
        this.setState({ bulkCredit })
    }

    togglePhotoCheck = (e) => {
        let id = e.target.getAttribute('data-id')
        
        if (e.target.checked) {
            this.state.checkedPhotos.push(id)
            this.setState({ checkedPhotos: this.state.checkedPhotos })
        } else {
            this.setState({ checkedPhotos: this.state.checkedPhotos.filter((p) => p !== id) })
        }

    }


    render() {
        return (
            <Container className="tree-form--photos">
                <Row>
                    <Col lg={9}>

                        <div className="tree-form--photo-gallery">

                            {this.state.images.sort((a, b) => a.sequence - b.sequence).map((photo) =>

                                <div className="tree-form--individual-photo" key={photo.link}>
                                    <div className="tree-form--individual-photo--image">
                                        <img alt="" src={photo.link} onError={this.errorLoadingImage} />
                                    </div>
                                    <span className="photo-check">
                                        <input 
                                            type="checkbox" 
                                            checked={this.state.checkedPhotos.includes(`${photo.photo_link_id}`) } 
                                            name={`${photo.photo_link_id}-photo-check`} 
                                            onChange={this.togglePhotoCheck} 
                                            data-id={photo.photo_link_id} 
                                            class="big-checkbox"

                                            />
                                    </span>
                                    <span className="photo-edit">
                                        <PhotoModal parentComponent={this} photo={photo} />
                                    </span>
                                    <span className="photo-delete" onClick={this.deletePhoto} data-photo-id={photo.photo_link_id}>
                                        <FaTrashAlt />
                                    </span>
                                </div>
                            )}
                        </div>

                    </Col>

                    <Col lg={3} className="photo-options">



                        <Form.Group>
                            <Form.Label className="d-block">
                                Upload New Photos
                            </Form.Label>
                            <input type="file" multiple id="photoUploader" name="filename" onChange={this.stageImageFiles} />
                            <br /><br />
                            <Form.Label className="d-block">
                                Credits
                            </Form.Label>
                            <input type="text" name="credits" onChange={this.changeCredits} value={this.state.credits} />


                            <span onClick={this.uploadImageFiles} className={`${this.state.saving ? "form-button-secondary" : "form-button-primary"}`}>
                                {this.state.saving ? "Uploading..." : "Upload Now"}
                            </span>

                        </Form.Group>


                        <Form.Group>
                            <Form.Label className="d-block">
                                Bulk Credit Update
                            </Form.Label>
                            <div>{this.state.checkedPhotos.length} Selected</div>
                            <input type="text" name="credits" onChange={this.changeBulkCredit} value={this.state.bulkCredit} placeholder="New credit text..." />
                            <span onClick={this.bulkEdit} className={`${this.state.bulkSaving ? "form-button-secondary" : "form-button-primary"}`}>
                                {this.state.bulkSaving ? "Saving..." : "Save"}
                            </span>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label className="d-block">
                                Other Options
                            </Form.Label>
                            <ReorderPhotos parentComponent={this} />
                        </Form.Group>

                    </Col>

                </Row>
            </Container >
        )
    }
}

const mapStateToProps = (state) => {
    return { searchCharacteristics: state.searchCharacteristics.data, treeDetail: state.treeDetail.data }
}

export default connect(mapStateToProps, {})(withRouter(Photos));
