import React from 'react';
import './HowToMeasure.css';

class SearchHelp extends React.Component {
    constructor(props) {
        super();

    }
    componentDidMount() {
        if (this.props.match.params.treeName) {
            let treeName = this.props.match.params.treeName;
            this.props.getTreeDetail(treeName);
        } else {
            console.log(`components.TreeDetail: no match`);
        }

    }

    render() {
        return (
            <div className="search-help container">
                <div class="row">
                    <div class="col-md-12">
                        <h1>
                            California Big Trees - How to Measure a Tree
                        </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <h3>
                            Points Formula
                        </h3>
                        <p>
                            The California Register of Big Trees uses American Forests' points formula to determine whether a tree is a champion.
                        </p>
                        <p>
                            Trunk circumference in inches<br />
                            + Height in feet<br />
                            + ¼ of the average crown spread in feet<br />
                            = Total Points
                        </p>

                        <h3>
                            Circumference
                        </h3>
                        <p>
                            To measure the tree's circumference, measure around the tree at 4-½ feet above the ground on the tree's uphill side.If the tree forks below or bulges at 4-½ feet, measure the circumference where the tree reaches normal size or tapers below the 4-½ foot point.For accurate measurement, use a diameter tape or regular tape measure.
                        </p>

                        <h3>
                            Height
                        </h3>
                        <p>
                            Measure the height from the ground level to the highest point.You can measure height with an instrument such as a laser hypsometer, clinometer,  or abney level.
                        </p>

                        <h3>
                            Crown Spread
                        </h3>
                        <p>
                            To find the tree's average crown spread, measure the widest point and the narrowest point of the crown. Add them together and divide by two to calculate the average crown spread.
                        </p>

                        <h3>
                            American Forests Tree Measuring Guidelines
                        </h3>
                        <p>
                            <a href="/AF-Tree-Measuring-Guidelines_LR.pdf">Measuring Guidelines Handbook</a> - A complete guide to accurate measurements.
                        </p>

                    </div>
                </div>

            </div>
        )

    }
}

export default SearchHelp
