import React from "react";
import { Container } from "react-bootstrap";
import "./BigTreeBanner.css";
// subcomponents
import BtSearchByName from "../BtSearchByName/BtSearchByName";

class BigTreeBanner extends React.Component {
  render() {
    return (
      <Container className="bigtree-banner-container" fluid>
        <Container>
          <h1 className="bigtree-banner-container-h1"><span className="bigtree-banner-title">California Big Trees</span></h1>
          <h3 className="bigtree-banner-container-h3">Search the Registry</h3>

          <div className="d-none d-lg-block">
            <BtSearchByName />
          </div>
        </Container>
      </Container>
    );
  }
}

export default BigTreeBanner;

