import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMapEvents} from 'react-leaflet'
import { Button } from 'react-bootstrap';
import './Map.css';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";

import redMarker from './red_marker.png'
import greenMarker from './green_marker.png'

import "leaflet/dist/leaflet.css";

const CALIFORNIA_CENTER = [36.778259, -119.417931];

const DEFAULT_LAYER = <TileLayer
   key={'default_layer'}
   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
   />

const SATELLITE_LAYER = <TileLayer
   key={'satellite_layer'}
   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
   url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
   subdomains={['mt1','mt2','mt3']}
   />

const DEFAULT_ZOOM = 7;

function getRandomInRange(from, to, fixed) {
    return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
    // .toFixed() returns string, so ' * 1' is a trick to convert to number
}

function obscurePosition(pos) {

   if(!Array.isArray(pos) || pos.length !== 2)
      return pos;

   let lat = randomWrappedShift(pos[0], 0.01, 90);
   let long = randomWrappedShift(pos[1], 0.01, 180);
   
   return [lat, long];
}

function randomWrappedShift(val, range, wrapVal) {
   
   let new_val = getRandomInRange(-range, range, 5) + val;

   if(Math.abs(new_val) > wrapVal) {
      const base = new_val > wrapVal ? wrapVal: -wrapVal;
      const remainder = new_val - base;
      new_val = -(base - remainder);
   }

   return new_val;
}

function Map(props) {
   const positions = props.positions.map(pt=> {
      const pos = [pt.latitude, pt.longitude];
      pt.position = pt.hidden? obscurePosition(pos): pos;
      return pt;
   });

   const defaultZoom = props.zoom? props.zoom: DEFAULT_ZOOM;

   const map_ctr = Array.isArray(positions) && positions.length?
      positions[0].position:
      CALIFORNIA_CENTER

   function TreePopup(props) {
      const pt = props.pt;
      return <Popup>
         <h5 style={{textAlign: 'center'}}>
            {pt.national_champion? 'National Champion': 'State Champion'}
         </h5>
         <Button
            onClick={() => window.location.href = `/bt-tree-detail/${pt.bt_id}`}>
            <small>
               <em>
               {pt.name_unformatted}
               <br/>
               {pt.commonName}
               </em>
            </small>
         </Button>
      </Popup>
   }

   function Markers() {
      const [zoom, setZoom] = useState(defaultZoom);

      const mapEvents = useMapEvents({
         zoomend: () => {
            setZoom(mapEvents.getZoom());
         },
      });

      return positions.map((pt, i) => {
         function renderMarker() {

            if(pt.hidden && zoom > 10) {
               return <Circle 
                  key={i}
                  center={pt.position}
                  fillColor="red" 
                  color='red'
                  radius={3000}>
                  <TreePopup pt={pt}/>
               </Circle>
            }

            const icon = L.icon({
               iconUrl: pt.hidden ? redMarker: greenMarker,
               shadowUrl: "",
               iconSize: [84.7, 70], 
               shadowSize: [50, 64], 
               iconAnchor: [22, 70], 
               shadowAnchor: [4, 62],  
               popupAnchor: [-3, -76] 
            });

            return <Marker 
               key={i} 
               position={pt.position} 
               icon={icon}>
               <TreePopup pt={pt}/>
            </Marker>
         }

         return renderMarker();
      });
   }

   function ScrollControl() {
      const map = useMapEvents({
         click: ()=> {
            map.scrollWheelZoom.enable();
         },
    })
      return "";
   }

   return <MapContainer center={map_ctr} zoom={defaultZoom} scrollWheelZoom={false}>
      {props.satelliteView? SATELLITE_LAYER : DEFAULT_LAYER}
      <ScrollControl />
      <Markers />
   </MapContainer>
}

export default Map;
