import React from 'react';
import { Col, Button } from 'react-bootstrap'
import { getSearchOptions, setSearchOptions } from "../../utilities/btSessionStorage"
import uuid from 'react-uuid';
import './ResultPagination.css';
import { NUMBER_OF_RESULTS } from '../../utilities/constants'

import {useSelector, useDispatch } from "react-redux";

const ResultPagination = () => {

   const dispatch = useDispatch();
   const searchSettings = useSelector(state => state.btData.searchSettings);

   const setScrollY = (e) => {
      try {
         let searchOptions = getSearchOptions();
         searchOptions.scroll_y = window.scrollY;
         setSearchOptions(searchOptions);
      } catch (err) {
         console.log(`components.ResultGallery.setScrollY: ${err}`);
      }
   }

   const handlePerPageClick = (e) => {
      try {
         setScrollY();
         const perPage = parseInt(e.target.value);
         if (searchSettings.resultsPerPage !== perPage) {
            let newSearchSettings = {
               ...searchSettings, 
               resultsPerPage: perPage, 
               activePage: 1
            };
            dispatch({type: "SET_SEARCH_SETTINGS", payload: newSearchSettings})
         }

      } catch (err) {
         console.log(`components.ResultPagination.handlePerPageClick: ${err}`);
      }
   }

   const renderButtons = () => {
      try {
         return NUMBER_OF_RESULTS.map(page => {
            return (
               <Button 
                  key={uuid()} 
                  variant="primary" 
                  className='border border-dark mr-1 result-pagination-btn' 
                  active={page === searchSettings.resultsPerPage} 
                  value={`${page}`} 
                  onClick={handlePerPageClick}>{page}</Button>
            )
         })
      } catch (err) {
         console.log(`components.ResultPagination.renderButtons: ${err}`);
         return null;
      }
   }

   return (
      <Col className="result-pagination">
         <div className="result-pagination-buttons">{renderButtons()}</div>
         <p className="align-right">results per page</p>
      </Col>
   )
}

export default ResultPagination;
