
export const setRegion = (region) => async (dispatch) => {
    try {
        if (region) {
            sessionStorage.ufeiRegion = region
            return dispatch({ type: "SET_REGION", payload: region });
        } else {
            sessionStorage.ufeiRegion = ""
            return dispatch({ type: "SET_REGION", payload: [] })
        }
    } catch (err) {
        console.log(`actions.setFilters.: ${err}`);
    }
}