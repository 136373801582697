// TODO - clean up reducers once components are cleaned up. Many of these aren't needed for Big Tree

import { combineReducers } from "redux";
import searchByName from "./searchByName";
import setSelelectedTree from "./setSelectedTree";
import getSearchResultsPageData from "./getSearchResultsPageData";
import getSearchCharacteristicsData from "./getSearchCharacteristicsData";
import getSearchByNameMultiResultData from "./getSearchByNameMultiResultData";
import btReducer from "./btReducer";
import { setRegion } from "./setFilters";

export default combineReducers({ suggestions: searchByName, selectedTree: setSelelectedTree, searchResults: getSearchResultsPageData, searchByNameMultiResult: getSearchByNameMultiResultData, region: setRegion, searchCharacteristics: getSearchCharacteristicsData, btData: btReducer });
