import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom'

import './UserAccountEdit.css'

import api from "../../apis/selectree_api";
import { auth } from "../../utilities/header"

import FormFieldPlainText from '../FormField/FormFieldPlainText'
import ResponsiveAccordion from '../ResponsiveAccordion/ResponsiveAccordion';

class UserAccountEdit extends React.Component {

    constructor(props) {
        super();

        let user = {}
        try {
            user = JSON.parse(localStorage.getItem('user'))
        } catch (err) {
            console.log(`Error in components.UserAccount.render: ${err}`);
        }

        this.state = {
            ...user,
            open: false,
            changed: false,
            new_password: "",
            confirm_password: "",
            errors: [] 
        }
    }

    updateUser = async () => {
        let data = this.state

        try {
            if (data.user_id) {
                let res = await api.put(`/users/edit`, data, auth);
                console.log(res.data)
                localStorage.setItem('user', JSON.stringify(res.data))
                window.location.href = '/account/edit'
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.actions.taxon: ${err}`);
        }
    }

    changePassword = async () => {
        let data = this.state

        try {
            if (data.user_id) {
                let res = await api.put(`/users/change-password`, data, auth);
                if (res.data.message === "Changed") {
                    this.setState({ password_saved: true, errors: [] })
                } else if (res.data.message === "Failed") {
                    this.setState({ password_saved: false,  errors: res.data.errors.errors })
                }

                console.log(res)
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.actions.taxon: ${err}`);
        }
    }

    render() {
        if(this.state.new_password !== this.state.confirm_password && this.state.password_saved) {
            this.setState({password_saved: false})
        }

        return (
            <div className="container pt-4 pb-4" >
                <h1 className="mb-2">Edit User Account</h1>

                <Link to="/account" className="form-button-secondary mb-2" >Back</Link>

                <div className="mb-4">
                    <ResponsiveAccordion title="USER INFORMATION">
                        <Container>
                            <Row>
                                <Col lg={4}>
                                    <FormFieldPlainText
                                        title="First Name"
                                        field="first_name"
                                        parentComponent={this}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <FormFieldPlainText
                                        title="Last Name"
                                        field="last_name"
                                        parentComponent={this}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <FormFieldPlainText
                                        title="Post Nominal"
                                        field="post_nominal"
                                        parentComponent={this}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <FormFieldPlainText
                                        title="Organization"
                                        field="organization"
                                        parentComponent={this}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <FormFieldPlainText
                                        title="Email"
                                        field="email"
                                        parentComponent={this}
                                    />
                                </Col>

                                <Col lg={12}>
                                    <span onClick={this.updateUser} className="form-button-primary"> Submit </span>&nbsp;&nbsp;&nbsp;
                                </Col>

                            </Row>
                        </Container>
                    </ResponsiveAccordion>
                </div>


                <div className="mb-4">
                    <ResponsiveAccordion title="CHANGE PASSWORD">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <FormFieldPlainText
                                        title="New Password"
                                        field="new_password"
                                        parentComponent={this}
                                        kind="password"
                                    />
                                </Col>
                                <Col lg={12}>
                                    <FormFieldPlainText
                                        title="Confirm New Password"
                                        field="confirm_password"
                                        parentComponent={this}
                                        kind="password"
                                    />
                                </Col>

                                {this.state.errors && this.state.errors.length > 0 ? 
                                
                                <Col lg={12}>
                                    {this.state.errors.map( (e) => 
                                        <div>- {e}</div>
                                    )}
                                </Col>

                                 : "" }

                                <Col lg={12}>
                                    {this.state.new_password !== this.state.confirm_password ? "Please make sure the passwords match." :
                                        <div>
                                            { this.state.password_saved ? <div>Your password is saved.</div> : "" }
                                            <span onClick={this.changePassword} className="form-button-primary"> Submit </span>
                                        </div>
                                    }
                                </Col>
                                
                                <Col lg={12}>
                                    <div class="user-account-edit--password-warning">
                                        <p>If you choose to reset, please choose a secure password. This can one of two things:</p>
                                        <p>#1. A password that is 10 or more characters, and has 1 Uppercase, 1 Lowercase, 1 Number, and 1 Symbol. Example: "Abcd!1234@"
                                        </p>
                                        <p>#2. A passphrase that is 20+ characters long. Example: "youcanchooseanythingyouwant"</p>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </ResponsiveAccordion>
                </div>
            </div>
        )
    }
}

export default UserAccountEdit;