import React from "react";
import htmlParser from 'react-html-parser';
import "./BtSearchByName.css";
import uuid from 'react-uuid';

class BtSearchByNameSuggestionCard extends React.Component {
  constructor(props) {
    super();

  }

  chooseTreeClickHandler = (e) => {
    try {
      let element = e.currentTarget
      const treeName = element.getAttribute('data-tree-name-unformatted');
      const treeId = element.getAttribute('data-tree-id');
      const searchTerm = this.props.parentComponent.state.searchTerm;
      let formattedSearchTerm = treeName.split(' ').join('&nbsp;');
      window.location.href = `/search-results/?searchTerm=${encodeURIComponent(treeName)}`
    } catch (err) {
      console.log(`component.BtSearchByName.chooseClickHandler: ${err}`);
    }
  }

  render() {
    let formattedSuggestion = this.props.suggestion

    return <div
      key={uuid()}
      className={`search-by-name-suggestion-card suggestion-card-${this.props.i} ${this.props.parentComponent.state.hoverEntry === this.props.i ? "suggestion-card-hover" : ""}`}
      //Use onMouseDown because it needs to fire before the onBlur event of the input. 
      onMouseDown={this.chooseTreeClickHandler}
      onMouseMove={this.props.parentComponent.moveMouseHover}
      data-tree-id={formattedSuggestion.option.redirect_id || formattedSuggestion.option.bt_id}
      data-tree-name={formattedSuggestion.option.main_name || formattedSuggestion.option.name_concat}
      data-tree-name-unformatted={formattedSuggestion.option.name_unformatted}
      data-list-position={this.props.i} >

      {/* The 'suggestion' prop has an info field that already has the formatted information (since it can vary between entries) */}
      <div className="label-medium">{htmlParser(formattedSuggestion.info[0])}</div>
      <div className="small">{formattedSuggestion.info[1]}</div>
      <div className="small">{formattedSuggestion.info[2]}</div>
    </div>
  }

}

export default BtSearchByNameSuggestionCard
