import React from 'react'
import TreeForm from './TreeForm'
import { Container, Spinner } from 'react-bootstrap'
import { DEFAULT_TREE_FIELDS } from '../../utilities/constants'
import { mapCallToState } from '../../utilities/mapDatabaseCallToState'
import ErrorPage from '../ErrorPage/ErrorPage';
import { Link } from 'react-router-dom'

import { Prompt } from 'react-router'

import { connect } from 'react-redux';
import { getSearchChar } from "../../actions/searchChar";

// action creators
import { getTreeDetail } from "../../actions/treeDetail";
import { linkSearchByName } from "../../actions/search";

import {auth} from '../../utilities/header'


import api from "../../apis/selectree_api";


class EditForm extends React.Component {
    constructor(props) {
        super();
        this.state = {
            ...DEFAULT_TREE_FIELDS(),
            noMatchingTrees: false,
            loading: false,
            changed: false
        }
    }

    alertUser = e => {
        if (this.state.changed) {
            e.preventDefault()
            e.returnValue = ''
        }
    }

    componentDidMount() {
        try {
            window.addEventListener('beforeunload', this.alertUser)

            let query = this.props.match.params.treeName;
            if (Number.parseInt(query)) {
                let treeName = Number.parseInt(query);

                this.props.getTreeDetail(treeName).then((res) => {
                    this.setState({ ...mapCallToState(res.payload.data) })
                })


            } else if (query.includes('-')) {
                query = query.replace(/-[^\w].|-/g, ' ');
                this.searchResults(query)
                    .then(data => {
                        // filter down all of the suggestions
                        let filteredResults = this.filterResults(data);
                        if (filteredResults.length === 1) {
                            const treeId = data[0];
                            window.history.pushState(null, null, `/tree-detail/${treeId}`)
                        } else if (filteredResults.length > 1) {
                            const treeSuggestions = `tree_suggestions=${filteredResults.join(',')}`;
                            return (document.location.href = `/search-results?filterData=${treeSuggestions}&activePage=1&resultsPerPage=30&sort=1`)
                        } else if (!filteredResults.length) {
                            query = query.split(' ').shift();
                            return (document.location.href = `search-results?filterData=genus=${query},1?tree_suggestions=&activePage=1&resultsPerPage=30&sort=1`)
                        } else {
                            this.setState({ noMatchingTrees: true })
                        }
                    });
            } else if (query) {
                // Current assumption is lone query is defaulting to genus
                return (document.location.href = `search-results?filterData=genus=${query},1?tree_suggestions=&activePage=1&resultsPerPage=30&sort=1`)
            } else {
                console.log(`components.TreeDetail: no match`);
            }


            this.props.getSearchChar();


        } catch (err) {
            console.log(`components.EditTree.componentDidMount: ${err}`)
        }
    }


    componentDidUpdate() {
        //ForceData makes it recall the database to re-get the information
        if (this.state.forceData) {
            let query = this.props.match.params.treeName;
            let treeName = Number.parseInt(query);

            this.props.getTreeDetail(treeName).then((res) => {
                this.setState({ ...mapCallToState(res.payload.data), forceData: false, loading: false })
            })
        }
    }

    searchResults = async (query) => {
        try {
            const { data } = await linkSearchByName(query);
            return data;
        } catch (err) {
            console.log(`components.TreeDetail.searchResults: ${err}`);
            return [];
        }
    }


    filterResults = (results) => {
        try {
            let filteredResults = [];
            let filterDict = {}

            results.map(result => {
                if (!filterDict[result]) {
                    filterDict[result] = 1;
                    filteredResults.push(result);
                }
                return null
            })

            return filteredResults;
        } catch (err) {
            console.log(`components.TreeDetail.filterResults: ${err}`);
            return [];
        }
    }


    submitForm = async () => {
        this.setState({ loading: true })

        let treeId = this.state.tree_id
        let treeData = this.state
        delete treeData.noMatchingTrees
        delete treeData.loading

        try {
            if (treeId) {
                await api.put(`/tree/update/${treeId}`, treeData, auth);
                this.setState({ forceData: true, changed: false })
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.components.EditTree.submitForm: ${err}`);
        }
    }

    duplicateTree = async () => {
        let treeId = this.state.tree_id
        let treeData = this.state
        delete treeData.noMatchingTrees
        delete treeData.loading

        try {
            if (treeId) {
                let response = await api.put(`/tree/duplicate`, treeData, auth);
                window.location.href = `tree-detail/${response.data.tree_id}/edit`
                //redirect to new tree with id
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.components.EditTree.duplicateTree: ${err}`);
        }
    }

    deleteTree = async () => {
        let treeId = this.state.tree_id

        try {
            if (treeId) {
                if (window.confirm("Are you sure you wish to delete? There is NO undoing this action.")) {
                    await api.delete(`/tree/delete/${treeId}`, auth);
                    window.location.href = `/`
                }
            } else {
                throw new Error('no id provided');
            }
        } catch (err) {
            console.log(`Error in client.components.EditTree.deleteTree: ${err}`);
        }
    }

    render() {
        return (
            this.props.treeDetail ?
                <Container>
                    <Prompt
                        when={this.state.changed}
                        message='Are you sure you wish to leave the page? Changes you made may not be saved.'
                    />

                    <h2 className="text-center category-label p-3 pt-lg-5 pb-lg-11 m-0">Edit Tree #{this.props.treeDetail.treeId}</h2>

                    <div style={{ textAlign: 'center', marginBottom: '.5rem' }}>
                        <span onClick={this.duplicateTree} className="form-button-primary" >Duplicate This Tree</span>&nbsp;
                        <Link className="form-button-primary" to={`/tree-detail/${this.props.treeDetail.treeId}`}>Back to Tree Page</Link>&nbsp;
                    </div>


                    {/* DEBUGGING CALLS TO PRINT OUT PROPS */}
                    {/*
                    {
                        this.props.searchCharacteristics ? Object.entries(this.props.searchCharacteristics).map((main, thing) =>
                            <div>
                                <h3>{main[0]}</h3>
                                {JSON.stringify(main[1])}

                            </div>
                        ) : ""
                    }

                    {
                        this.props.treeDetail ? Object.entries(this.props.treeDetail).map((main, thing) =>
                            <div>
                                <h3>{main[0]}</h3>
                                {JSON.stringify(main[1])}
                            </div>
                        ) : ""
                    } 
                    
                    */}

                    <TreeForm parentComponent={this} />

                    <div style={{ textAlign: 'center', marginBottom: '.5rem' }}>
                        <span onClick={this.submitForm} className={this.state.loading ? "form-button-secondary" : "form-button-primary"}> {this.state.loading ? "Saving..." : "Submit Form"}</span>&nbsp;
                        <Link className="form-button-primary" to={`/tree-detail/${this.props.treeDetail.treeId}`}>Back to Tree Page</Link>&nbsp;
                        <span onClick={this.deleteTree} className="form-button-secondary"> Permanently Delete </span>
                    </div>
                </Container>

                :

                <Container xs={{ span: 4, offset: 4 }} className="text-center">

                    {this.state.noMatchingTrees ? <ErrorPage message={"There are no trees found that match that query."} /> :

                        <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}
                </Container>)
    }
}

const mapStateToProps = (state) => {
    return { treeDetail: state.treeDetail.data, searchCharacteristics: state.searchCharacteristics.data }
}

export default connect(mapStateToProps, { getTreeDetail, getSearchChar })(EditForm);
