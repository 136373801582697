import React, { useEffect, useRef, useState } from 'react';
import { Container , Carousel} from 'react-bootstrap';
import './TreeDetailGallery.css';

function ImageGallery(props) {
   const {images} = props;
   const [mainImg, updateMainImg] = useState(0);
   const [pictureModal, updatePictureModal] = useState(false);
   const [images_data, updateImagesData] = useState([]);

   const [windowSize, updateWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight
   });

   useEffect(() => {
      const handleWindowResize = () => {
         updateWindowSize({
            width: window.innerWidth, 
            height: window.innerHeight
         });
      };

      window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

   })
   
   useEffect(() => {

      async function load_images() {
         
         let imgs = await Promise.all(images.map(async img => {
            return get_image_data(`${process.env.REACT_APP_BASE_IMAGE_URL}${img.link}`)
               .catch(e => { console.log(e) 
                  return {url: `/images/default-tree.png`, w: 500, h: 500, a: 1}
               });
         }));

         updateImagesData(imgs)
      }

      load_images();
   }, []);

   function ImageCarousel(imgs) {
      return (
         <Carousel
            slide={false}
            onSelect={(i, e) => {
               if(e !== undefined)
                  e.stopPropagation()
            }}
            defaultActiveIndex={mainImg}>
            {imgs.map((img, num) => 
               <Carousel.Item key={num}>
                  <img
                     className='carousel-image'
                     onClick={e => e.stopPropagation()}
                     src={img.url} />
               </Carousel.Item >
            )}
         </Carousel>
      )
   }

   function get_image_data(url) {
      return new Promise((resolve, reject) => {
         const img = new Image();
         img.onload = () => resolve({url: url, w: img.width, h: img.height, a: img.width/img.height});
         img.onerror = () => reject();
         img.src = url;
      });
   }

   function determine_columns(imgs) {
      var tall_cnt = 0;
      var wide_cnt = 0;
      var max_col_cnt = 4;
      var img_len = imgs.length;

      if(windowSize.width < 750) {
         return 1;
      }

      if(img_len === 2)
         return 2;

      for(let img of imgs) {
         img.a > 1? wide_cnt++: tall_cnt++;
      }

      let col_cnt;

      if(tall_cnt === 0) {
         max_col_cnt = 3;
         col_cnt = img_len;
      }
      else if(wide_cnt === 0)
         col_cnt = img_len;
      else
         col_cnt = tall_cnt > wide_cnt? imgs.length: imgs.length - 1;

      return col_cnt <= max_col_cnt? col_cnt: max_col_cnt;
   }

   function MasonaryGallery(props) {
      const {imgs, col_count, gap} = props

      return<>
      <div style={{columnCount: col_count, columnFill: "balance",columnGap: 0}}>
         {imgs.map((img, num) => { 
            return <div key={num} style={
               imgs.length === 1? {margin: "0 auto", columnSpan: "all"} : {padding: gap/2}}>
               <img 
                  className={
                     imgs.length === 1? 'wrap main-gallery-image': 'gallery-image'}
                  onClick={() => {
                           updateMainImg(num)
                           updatePictureModal(true)
                  }}
                  key={num}
                  src={img.url} />
            </div>
         })}
      </div>
      {pictureModal ?
         <div id='modal-background'
               onClick={()=> updatePictureModal(false)}
            >
            <div className='modal-main'>
               {ImageCarousel(imgs)}
               </div>
         </div>
         : ""}
      </>
   }

   function buildCredits(photos) {
      let seen = []      

      for (let p of photos) {
         if(!seen.includes(p.credit))
            seen.push(p.credit)
      }

      let credit = seen.length === 1? seen[0]: seen.join(", ");

      return <div style={{paddingTop: '1rem', color: 'grey', textAlign: 'left'}}>
         {credit !== "" && credit !== undefined? `Photos by: ${credit}`: ""}
      </div>
   }

   return <Container>
      <div>
         {Array.isArray(images) && images_data.length > 0? 
            <MasonaryGallery imgs={images_data} col_count={determine_columns(images_data)} gap={0}/>: ""}
      </div>
         {buildCredits(images)}
   </Container>
}

export default ImageGallery;
