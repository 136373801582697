import React, { useState } from 'react'
import NominationForm from './NominationForm'
import { Button, Container, Form } from 'react-bootstrap'
import toast, { Toaster } from 'react-hot-toast'
import ErrorPage from '../ErrorPage/ErrorPage';
import { Prompt } from 'react-router'
import api from '../../apis/selectree_api'

const ERROR_PREFIX = "componets.Nominate.Nominate"

function Nominate() {
   const [state, updateState] = useState({
      validated: false,
      hasError: false,
      noMatchingTrees: false,
      loading: false,
      changed: false
   })

   const [form, setForm] = useState({photos: []})

   function handleForm (name, v) {
      if(state.changed === false) 
         updateState({...state, changed: true});

      if(Array.isArray(form[name]) && !Array.isArray(v)) {
         setForm(prev => {
            return setForm({...prev, [name]: [...prev[name], v]});
         });
      } else {
         setForm(prev => {
            return {...prev, [name]: v};
         });
      }
   };

   async function sendForm(form) {
      switch(form.owner_status) {
         case "isNominator":
            form.owner_status = true;
            form.owner_name = form.contact_name;
            form.owner_email = form.contact_email;
            form.owner_phone = form.contact_phone;
            form.owner_address = form.contact_address;
            form.owner_city = form.contact_city;
            form.owner_state = form.contact_state;
            form.owner_zip = form.contact_zip;
            break;
         case "isUnknown":
            form.owner_status = false;
            break;
         default:
            form.owner_status = true;
            break;
      }

      try {

         const {photos,...formData} = form;
         const payload = new FormData();

         for(let key of Object.keys(formData)) {
            payload.append(key, form[key]);
         }

         for(let photo of photos) {
            payload.append('photo_credits', photo.credit);
            payload.append('photos', photo.file);
         }

         const config = {
            headers: {
               "content-type": "multipart/form-data",
               "Access-Control-Allow-Origin": "*"
            }
         };

         let nomination = await api.post(`/bigtrees/nomination/create`, payload, config);

         return nomination.status;
      } catch(err) {
         console.log(`${ERROR_PREFIX}.sendForm: ${err}`)
      }
   }

   function checkImages(photos, notification) {

      if(photos.length < 3) {
         toast.error(`Must have 3 images! Currently ${photos.length}`, 
            {id: notification});
         return false;
      }

      let invalidImages = [];

      for(let i = 0; i < photos.length; i++) {
         let type = photos[i].file?.type.split('/')[1].toLowerCase();
         if(type !== "jpeg" && type !== "png") {
            invalidImages.push(i);
         }
      }

      if(invalidImages.length > 0) {
         let imgPosStr = `Image${invalidImages.length === 1? '': 's'} `;
         for(let i of invalidImages) {
            imgPosStr = imgPosStr + (i === invalidImages.length - 1? `${i+1}` : `${i+1}, `);
         }

         toast.error(`${imgPosStr} must be a jpg or png`, {id: notification});
         return false;
      }

      return true;
   }

   function checkForm(target, notification) {
      if(target.checkValidity()) {
         return true;
      } 
      const missingFields = [];

      const checkFormValue = (name, msg) => {
         if(form[name] === undefined) {
            missingFields.push(msg);
         }
      }

      checkFormValue("tree_id", "Tree Name");
      checkFormValue("circumference", "Circumference");
      checkFormValue("crown_width", "Average Crown Spread");
      checkFormValue("height", "Total Height");

      checkFormValue("tree_longitude", "Longitude");
      checkFormValue("tree_latitude", "Latitude");
      checkFormValue("tree_location", "Tree Location");
      checkFormValue("tree_county", "Tree County");
      checkFormValue("property_type", "Property Type");

      checkFormValue("nominator_names", "Nominator Names")
      checkFormValue("measurer_names", "Measurer Names")
      checkFormValue("date_measured", "Date measured");

      checkFormValue("contact_name", "Contact Name");
      checkFormValue("contact_email", "Contact Email");
      checkFormValue("contact_phone", "Contact Phone");
      checkFormValue("contact_address", "Contact Address");
      checkFormValue("contact_city", "Contact City");
      checkFormValue("contact_state", "Contact State");
      checkFormValue("contact_zip", "Contact Zip");


      if(form.owner_status === undefined) {
         checkFormValue("owner_name", "Owner Name");
         checkFormValue("owner_email", "Owner Email");
         checkFormValue("owner_phone", "Owner Phone");
         checkFormValue("owner_address", "Owner Address");
         checkFormValue("owner_city", "Owner City");
         checkFormValue("owner_state", "Owner State");
         checkFormValue("owner_zip", "Owner Zip");
      }

      if(missingFields.length > 0) { 
         let errorMsg = '';

         for(let i = 0; i < missingFields.length && i < 3; i++) {
            const endStr = i === missingFields.length - 1 || i === 2?
               `${missingFields.length > 3? '... ': ' '}`:
               `, `

            errorMsg = errorMsg + missingFields[i] + endStr;
         }
         toast.error(`Missing ${errorMsg}field${missingFields.length ===1?'':'s'}`, 
            {id: notification});
      } else if(form.photos.length === 0) {
         toast.error(`No photos uploaded!`, {id: notification});
      } else {
         toast.error('Required information is invalid!', {id: notification});
      }

      return false;
   }

   const submitForm = (event) => {
      updateState({ loading: true })
      try {
         const notification = toast.loading('Loading...');
         const f = event.currentTarget;
 
         if(checkForm(f, notification) === true && checkImages(form.photos, notification) === true) {
            const nom_link = `${window.location.origin}/edit/nomination/`;
            
            sendForm({...form, nom_link: nom_link}).then(status => {
               if(status === 201) {
                  toast.success('Success, Thanks for supporting Big Tree!',{id: notification});
                  setTimeout(() => {window.location.href = `/`;}, 2000);
               } else {
                  toast.error('Oh no! Something went wrong on our end',{id: notification});
               }
            });

         } else {
            updateState({hasError: true})
            event.preventDefault();
            event.stopPropagation();
         } 

         event.preventDefault();
         f.classList.add('was-validated')

      } catch (err) {
         console.log(`Error in client.components.Nominate.submitForm: ${err}`);
      }
   }
   
   return (<Container>
      <Prompt 
         message='Are you sure you wish to leave the page? Changes you made may not be saved.'
         />

      <h2 className="text-center category-label p-3 pt-lg-4 pb-lg-11 m-0">Nominate a Tree</h2>
      <Form 
         noValidate 
         onSubmit={submitForm}>
         <NominationForm form={form} handleForm={handleForm} hasError={state.hasError} />
         <div className="col-md-12 text-center">
            <Button type="submit">Nominate a Tree</Button>
         </div>
         <br/>
      </Form>
      <Toaster />
   </Container>)
}

export default Nominate;
