import React from 'react'
import ResponsiveAccordion from '../ResponsiveAccordion/ResponsiveAccordion'
import { Row, Col, Container, Form } from 'react-bootstrap'
import TextInput from '../FormField/TextInput'
import MenuInput from '../FormField/MenuInput'
import MultiCheckBox from '../FormField/MultiCheckBox'
import PhotoInput from '../FormField/PhotoInput'
import TreeSelect from '../FormField/TreeSelect'
import './NominationForm.css'

import {STATE_OPTIONS} from '../../utilities/constants'
import {COUNTY_OPTIONS} from '../../utilities/constants'

const propertyOptions = [
   {value: "private", label: "Private"},
   {value: "public", label: "Public"},
   {value: "unknown", label: "Unknown"}
]

const coordinateDirections =  <div>
      <h3>How do I get Coordinates?</h3>
      <p>1. Use <a href="https://www.google.com/maps " className="link-primary"><b>Google Maps</b></a> in a web browser to find your tree’s location.</p>
      <p>2. Mark the tree’s location on the map by right-clicking or ctrl-clicking the location.</p>
      <p>3. From the contextual menu, select <b>Whats here?</b> An info card with coordinates will appear.</p>
      <p>4. Copy the entire latitude and longitude string of numbers, and paste it into their respective fields.</p>
      <p>5. <b>Note:</b> make sure Google Maps is not in Lite mode. If you use Google Maps in Lite mode, you won’t be able to get the coordiantes of a place</p>
      <h3>Mobile & Tablets</h3>
      <p>1. Use the Google Maps app to find your tree’s location</p>
      <p>2. Mark the tree’s location on the map by touching and holding the location, until a marker appears. The coordinates should appear in the search box at the top of the display.</p>
      <p>3. Copy the entire latitude and longitude string of numbers, and paste it into their respective fields.</p>
      <h3>Another Question?</h3>
      <p>Questions regarding California Big Trees can be directed to Matt Ritter at <a href="mailto:mritter@calpoly.edu" className="link-primary"><b>mritter@calpoly.edu</b></a></p>
   </div>

function TreeInfo(props) {

   const {handleForm, hasError, form } = props
   return(
   <Container className="tree-form-card--nominate-tree-information">
         <Row>
            <Col xs={12} lg={6}>
               <TreeSelect 
                  label="Tree Name"
                  name="tree_id"
                  defaultTree={{label: form.scientific_name, value: form.tree_id}}
                  onChange={handleForm}
                  hasError={hasError}
                  required
               />
            </Col>
         </Row>
         <Row>
            <Col xs={12} lg={4}>
               <TextInput 
                  label="Circumference (Inches)"
                  name="circumference"
                  defaultValue={form.circumference}
                  onChange={handleForm}
                  pattern="^[.]?(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$"
                  errorMessage="Must be a positive number"
                  required={true}
                  />
            </Col>
            <Col xs={12} lg={4}>
               <TextInput 
                  label="Average Crown Spread (Feet)"
                  name="crown_width"
                  defaultValue={form.crown_width}
                  onChange={handleForm}
                  errorMessage="Must be a positive number"
                  pattern="^[.]?(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$"
                  required
                  />
            </Col>
            <Col xs={12} lg={4}>
               <TextInput
                  label="Total Height (Feet)"
                  name="height"
                  defaultValue={form.height}
                  onChange={handleForm}
                  pattern="^[.]?(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$"
                  errorMessage="Must be a positive number"
                  required
                  />
            </Col>
         </Row>

         <h3>TOTAL POINTS: { (Number.parseInt(form.height) || 0) + 
            ((Number.parseInt(form.crown_width) || 0)/4) +
            Number.parseInt((form.circumference) || 0) }</h3>
   </Container>)
}

function TreeLocation(props) {
   const {handleForm, hasError, form} = props
   return (
      <Container className="tree-form-card--nominate-tree-information">
         <Row>
            <Col xs={12} lg={3}>
               <TextInput
                  label="Latitude"
                  name="tree_latitude"
                  defaultValue={form.tree_latitude}
                  onChange={handleForm}
                  pattern="[\-\+]?[\.]?([1-8]?\d(\.\d+)?|90(\.0+)?)"
                  errorMessage="Must be a number between -90 and 90"
                  required={true}
                  />
            </Col>
            <Col xs={12} lg={3}>
               <TextInput
                  label="Longitude"
                  name="tree_longitude"
                  defaultValue={form.tree_longitude}
                  onChange={handleForm}
                  pattern="[\-\+]?[\.]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)"
                  errorMessage="Must be a number between -180 and 180"
                  required={true}
                  />
            </Col>
            <Col xs={12} lg={3}> 
               <MenuInput
                  label='County'
                  name='tree_county'
                  value={COUNTY_OPTIONS.find(opt => form.tree_county === opt.value)}
                  options={COUNTY_OPTIONS} 
                  onChange={handleForm}
                  errorMessage="Must select a county"
                  required={true}
                  hasError={hasError} />
            </Col>
            <Col xs={12} lg={3}>
               <MenuInput
                  label='Property Type'
                  name='property_type'
                  value={propertyOptions.find(opt => form.property_type === opt.value)}
                  options={propertyOptions} 
                  onChange={handleForm}
                  errorMessage="Must select a property type"
                  required={true}
                  hasError={hasError} />
            </Col>
            <Col xs={12} lg={12}>
               <TextInput
                  label="Location Description"
                  name="tree_location"
                  defaultValue={form.tree_location}
                  onChange={handleForm}
                  errorMessage="Must have a location description"
                  required={true}
                  />
            </Col>
            <Col xs ={12} lg={4}>
               <Container>
                  <Form.Group> 
                     <Form.Check 
                        type="switch"
                        id="tree-hidden-switch"
                        defaultChecked={form.hidden}
                        onInput={e => form.hidden === undefined || form.hidden === 0? 
                           handleForm("hidden", 1):
                           handleForm("hidden", 0)
                     }
                        label="Hide tree location from the public"/> 
                  </Form.Group>
               </Container> 
            </Col> 
         </Row>
         {coordinateDirections}
      </Container>)
}

function contactFields(prefix, onChange, hasError, form) {
   return (
      <Row>
         <Col xs={12} lg={6}>
            <TextInput
               label="Name"
               name={`${prefix}_name`}
               defaultValue={form[`${prefix}_name`]}
               onChange={onChange}
               errorMessage="Name is required"
               required={true}
               />
         </Col>
         <Col xs={12} lg={3}>
            <TextInput
               label="Email"
               name={`${prefix}_email`}
               defaultValue={form[`${prefix}_email`]}
               type="email"
               onChange={onChange}
               errorMessage="Email is required"
               required={true}
               />
         </Col>
         <Col xs={12} lg={3}>
            <TextInput
               label="Phone"
               name={`${prefix}_phone`}
               defaultValue={form[`${prefix}_phone`]}
               onChange={onChange}
               pattern="[\+]?(\d{1,3}[\s\.\-]?)?\(?\d{3}\)?[\s\.\-]?\d{3}[\s\.\-]?\d{4}"
               errorMessage="Format not valid follow: (optional +)(Country Code)(3 digit Area Code)(3 digit number)(4 digit number)"
               required={true}
               />
         </Col>
         <Col xs={12} lg={4}>
            <TextInput
               label="Address"
               name={`${prefix}_address`}
               defaultValue={form[`${prefix}_address`]}
               onChange={onChange}
               errorMessage="Address is required"
               required={true}
               />
         </Col>
         <Col xs={12} lg={4}>
            <TextInput
               label="City"
               name={`${prefix}_city`}
               defaultValue={form[`${prefix}_city`]}
               onChange={onChange}
               errorMessage="City is required"
               required={true}
               />
         </Col>
         <Col xs={12} lg={2}>
            <MenuInput
               label='State'
               name={`${prefix}_state`}
               value={STATE_OPTIONS.find(opt=> form[`${prefix}_state`] === opt.value)}
               options={STATE_OPTIONS} 
               onChange={onChange}
               errorMessage="State is required"
               required={true}
               hasError={hasError} />
         </Col>
         <Col xs={12} lg={2}>
            <TextInput
               label="Zip"
               name={`${prefix}_zip`}
               defaultValue={form[`${prefix}_zip`]}
               onChange={onChange}
               pattern="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
               errorMessage="Must be a valid zipcode"
               required={true}
               />
         </Col>
      </Row>)
}

function NominatorInfo(props) {
   const {handleForm, hasError, form} = props
   
   function formatDate(dateStr) {
      let s = String(dateStr).split("T")
      return s.length > 1? s[0]: dateStr;
   }

   return(
      <Container className="tree-form-card--nominator-information">
         <Row>
            <Col xs={12} lg={12}>
               <TextInput
                  label="Nominator Name(s)"
                  name="nominator_names"
                  defaultValue={form.nominator_names}
                  onChange={handleForm}
                  errorMessage="Nominator Name(s) is required"
                  required
                  />
            </Col>
            <Col xs={12} lg={6}>
               <TextInput
                  label="Measurer Name(s)"
                  name="measurer_names"
                  defaultValue={form.measurer_names}
                  onChange={handleForm}
                  errorMessage="Measurer Name(s) is required"
                  required
                  />
            </Col>
            <Col xs={12} lg={6}>
               <TextInput
                  label="Date Measured"
                  name="date_measured"
                  defaultValue={formatDate(form.date_measured)}
                  type="date"
                  onChange={handleForm}
                  errorMessage="Date Measured is required"
                  required
                  />
            </Col>
         </Row>
         <h3>Contact Info</h3>
         <br></br>
         {contactFields("contact", handleForm, hasError, form)}
      </Container>);
}

function OwnerInfo(props) {
   const {handleForm, hasError, form} = props;
   return(
      <Container className="tree-form-card--owner-information"> 
         {contactFields("owner", handleForm, hasError, form)}
      </Container>);
}

const ownerStatus = [
   {label: "Owner is nominator", value: "isNominator"},
   {label: "Owner is unknown", value: "isUnknown"}
]

function NominationForm(props) {
   const {handleForm, hasError, form} = props;
     return (<div>
      <Row>
         <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Tree Information">
               <TreeInfo form={form} handleForm={handleForm} hasError={hasError}/>
            </ResponsiveAccordion>
         </Col> 
         <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Tree Location">
               <TreeLocation form={form} handleForm={handleForm} hasError={hasError}/>
            </ResponsiveAccordion>
         </Col> 
         <Col sm={12} className="mb-4">
            <ResponsiveAccordion title="Nominator Information">
               <NominatorInfo form={form} handleForm={handleForm} hasError={hasError}/>
            </ResponsiveAccordion>
         </Col>
         <Col>
            <MultiCheckBox 
               options={ownerStatus} 
               name='owner_status'
               defaultValue={form.owner_status}
               handleForm={handleForm}/>
         </Col>
         {form.owner_status === undefined || form.owner_status === null?
            <Col sm={12} className="mb-4">
               <ResponsiveAccordion title="Owner Information">
                  <OwnerInfo form={form} handleForm={handleForm} hasError={hasError}/>
               </ResponsiveAccordion>
            </Col>: ""}
         <Col xs={12} lg={150} className="mb-4">
            <ResponsiveAccordion title="Notes">
               <Container>
                  <TextInput
                     name="notes"
                     defaultValue={form.notes}
                     onChange={handleForm}
                     />
               </Container>
            </ResponsiveAccordion>
         </Col>
         <Col xs={12} lg={150} className="mb-4">
            <ResponsiveAccordion title="Photos">
               <Container>
                  <div>Accepted formats: jpeg png</div>
                  <PhotoInput handleForm={handleForm} form={form}/>
               </Container>
            </ResponsiveAccordion>
         </Col>
      </Row>
     </div>)
}

export default NominationForm;
