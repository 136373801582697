import React from 'react'
import ResponsiveAccordion from '../ResponsiveAccordion/ResponsiveAccordion'
import { Row, Col } from 'react-bootstrap'

import NameInfo from '../TreeFormCard/NameInfo'
import Photos from '../TreeFormCard/EditPhotos/Photos'


class TreeForm extends React.Component {
    constructor(props) {
        super()

        this.state = {

        }
    }


    render() {
        return <div>
            <Row>
                <Col sm={12} className="mb-4">
                    <ResponsiveAccordion title="Name Information">
                        <NameInfo parentComponent={this.props.parentComponent} 
                        parentState={this.props.parentComponent.state} 
                        />
                    </ResponsiveAccordion>
                </Col>

                <Col sm={12} className="mb-4">
                    <ResponsiveAccordion title="Photos">
                        <Photos parentComponent={this.props.parentComponent} 
                        parentState={this.props.parentComponent.state} 
                        />
                    </ResponsiveAccordion>
                </Col>
            </Row>
        </div>
    }

}


export default TreeForm;