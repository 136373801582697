import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import Select from "react-select";
import "./ResultSort.css";
import reactSelectStyles from "../../customUI/react-select-options";
import {useSelector, useDispatch } from "react-redux";

const ResultSort = (props) => {

   const dispatch = useDispatch();
   const btState = useSelector(state => state.btData)
   const searchData = btState.searchResults;
   const searchSettings = btState.searchSettings;

   const options = [
      { label: "Scientific Name A-Z", value: 1 },
      { label: "Scientific Name Z-A", value: 2 },
      { label: "Common Name A-Z", value: 3 },
      { label: "Common Name Z-A", value: 4 }
   ];

   useEffect(()=> {
      sortResults(searchSettings !== undefined? searchSettings.sort: 1);
   }, []);

   async function sortResults(sort) {
      let pageResults = searchData.pageResults || [];

      try {
         switch (sort) {
            case 2:
               pageResults = pageResults.sort((a, b) => {
                  return a.name_unformatted < b.name_unformatted
               })
               break;
            case 3:
               pageResults = pageResults.sort((a, b) => {
                  return a.commonName > b.commonName
               })
               break;
            case 4:
               pageResults = pageResults.sort((a, b) => {
                  return a.commonName < b.commonName
               })
               break;
            default:
               pageResults = pageResults.sort((a, b) => {
                  return a.name_unformatted > b.name_unformatted
               })
            break;
         }

         let newSearchData = searchData || {totalResults: pageResults.length};
         newSearchData.pageResults = pageResults;

         let newSearchSettings = searchSettings || {};
         newSearchSettings.sort = sort;

         dispatch({ type: "SET_BT_DATA", payload: {
            searchResults: newSearchData,
            searchSettings: newSearchSettings
         }});

      } catch (err) {
         console.log(`components.ResultSort.setSortResults: ${err}`);
      }
   }

   function selectChange(e) {
      try {
         const val = e.value;
         sortResults(val);
      } catch (err) {
         console.log(`components.ResultSort.selectChange: ${err}`);
      }
   }

   return (
      <Col className="result-sort-width">
         <Form.Group controlId="result-sort">
            <Select 
               options={options} 
               styles={reactSelectStyles} 
               placeholder="Sort Results By..." 
               onChange={selectChange} 
               defaultValue={options[searchSettings.sort - 1 ] || options[ 0 ]}
               />
         </Form.Group>
      </Col>
   )
}

export default ResultSort;
