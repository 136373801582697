import React from "react";
import { Form } from "react-bootstrap";
import Select from 'react-select';
// react-select styling options
import reactSelectStyles from "../../customUI/react-select-options";
import "./FormField.css"

class FormFieldText extends React.Component {
    constructor(props) {
        super(props);

        if (props.parentComponent.state) {
            let value = props.parentComponent.state[props.field]
            if (value === undefined || value === null) {
                console.log(`Components.FormField.FormFieldText- Cannot find field ${this.props.field} on the parent component.`)
            }
        } else {
            console.log(`Components.FormField.FormFieldText- Please pass in the parent component that has the state.`)
        }


        this.state = {
        }
    }

    clearOption = () => {
        this.props.parentComponent.setState({ [this.props.field]: 0, changed: true  })
    }

    change = (selected) => {
        try {
            let newValue = selected.label
            let field = this.props.field
            this.props.parentComponent.setState({ [field]: newValue, changed: true  })
        } catch (err) {
            console.log(`Components.FormField.FormFieldText.change()-  ${err}`)
            return null
        }
    }

    render() {
        let defaultValue = this.props.parentComponent.state[this.props.field]
        let hideClearButton = (!defaultValue || defaultValue === 0 || defaultValue === "0")
        if(this.props.required) { hideClearButton = true }


        if (this.props.options) {
            this.props.options.forEach((option) => {
                if (defaultValue === option.label) { defaultValue = option }
            })
        } else {
            console.log(`Components.FormField.FormFieldText.render()- No options provided for ${this.props.field}`)
        }

        return (
            <Form.Group className="form-field-text">
                <Form.Label>{this.props.title}
                    { hideClearButton ? "" : <span onClick={this.clearOption} className="form-field-clear-field-button">X</span> }
                </Form.Label>
                <Select defaultValue={defaultValue} options={this.props.options} styles={reactSelectStyles} onChange={this.change} value={defaultValue} />
            </Form.Group>
        )
    }
}

export default FormFieldText