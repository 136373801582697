import React from 'react';
import { Link } from 'react-router-dom'
import './UserAccount.css'

import { checkRole } from '../../utilities/user'

class UserAccount extends React.Component {
    constructor(props) {
        super();

    }
    componentDidMount() {

    }

    render() {

        let user = {}
        try {
            user = JSON.parse(localStorage.getItem('user'))
        } catch (err) {
            console.log(`Error in components.UserAccount.render: ${err}`);
        }

        return (
            <div className="container pt-4 pb-4 text-center" >
                <h1>Your Account</h1>


                <div className="user-account-panel">
                    <p><b>Name: </b> {user.first_name} {user.last_name}{user.post_nominal ? `, ${user.post_nominal}` : ""}</p>
                    <p><b>Email: </b> {user.email}</p>
                    <p><b>Role: </b> {user.role_name}</p>
                    <p><b>Organization: </b> {user.organization}</p>

                    {!user.first_name ? <div>Please finish filling out your account <Link to="/account/edit">here</Link></div> : ""}
                    
                    <hr />
                    
                    <Link to="/account/edit" className="form-button-secondary mb-2">Edit Your Account</Link>
                    <br />
                    {checkRole([3]) ? <>
                        <Link to="/account/invite" className="form-button-secondary mb-2">Invite New Member</Link>
                        <br />
                        <Link to="/account/users" className="form-button-secondary mb-2">Change Roles</Link>
                    </> : ""}
                </div>

            </div >
        )

    }
}

export default UserAccount