import React, { useState } from 'react'
import './Login.css'
import { Button, Form, NavDropdown} from 'react-bootstrap'
import api from "../../apis/selectree_api";
import { auth } from "../../utilities/header"
import { checkRole } from '../../utilities/user'
import { FaUserCircle } from "react-icons/fa"
import TextInput from "../FormField/TextInput"

function LoginForm(props) {
   const {form, handleForm} = props;
   
   return <div>
      <h2>Log In</h2>
      <p>Please enter your email & password.</p>

      <TextInput 
         label={"Email"} 
         name="email"
         value={form.email}
         onChange={handleForm} 
         id='login-email-form'
         />
      <TextInput 
         label={"Password"} 
         name="password"
         type="password"
         value={form.password}
         onChange={handleForm} 
         id='login-password-form'
         />
      {form.error ? <div>{form.error}</div> : "" }
      <Button type="submit">Login</Button>
      
      <p  onClick={() => handleForm("toggleSide", false)}>Forgot your password?</p>
   </div>
}

function ForgotPassword(props) {
   const {form, handleForm} = props;
   
   return <div>
      <h2>Forgotten Password</h2>
      <p>Please enter your email. You will recieve a link to reset your password.</p>

      <Form onSubmit={()=>{}}>
         <TextInput 
            label={"Your Email"} 
            name="forgotten_password_email"
            value={form.forgotten_password_email}
            onChange={handleForm} 
            id='forgotten-password-email-form'
            />
         <Button type="submit">Send Email</Button>

         {form.email_sent ? <p>If an account with that name exists, the email was sent.<br />Please check your inbox.</p> : "" }

         <p onClick={() => handleForm("toggleSide", true)}>Login</p>
      </Form>
   </div>
}

function Login (props) {

   const {defaultModal } = props;

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('loggedIn');
    window.location.href = window.location.href;
  };

  const login = (data) => {
    localStorage.setItem('user', JSON.stringify(data.response))
    localStorage.setItem('token', data.token);
    localStorage.setItem('loggedIn', true);
    window.location.href = window.location.href;
  };

   const [modal, updateModal] = useState(defaultModal);
   const [state, updateState ] = useState({
      modalOpen: false,
      email: "",
      password: "",
      forgotten_password_email: "",
      toggleSide: true,
      email_sending: false,
      email_sent: false,
      error: ""
   });

   const loggedIn = localStorage.getItem('loggedIn')

   async function submitForm(e) {
      e.preventDefault();

      updateState({...state, error: ""});

      let email = state.email;
      let password = state.password;

      try {
         let res = await api.post(`/auth/login`, { email, password });
         login(res.data);
      } catch (err) {
         if(err?.response?.data?.message){
            updateState({...state, error: err.response.data.message });
         } else {
            updateState({...state, error: "Unkown error." })
            console.log(`Error in client.components.login.submitForm: ${err}`);
         }
      }
   }

   function handleForm(name, v) {
      updateState({...state, [name]: v});
   }

   return <div>
      <NavDropdown
         className="login-nav-menu"
         title={
         loggedIn?
            <FaUserCircle size='1.5em'/>:
            <Button className='btn btn-dark btn btn-primary'>Login</Button>
      }
         alignRight
         onClick={()=> {
            if(!loggedIn) {
               updateModal(true);
            }
         }}
         drop={'end'}
         id={`login-menu${loggedIn? '-user':'-no_user'}`}>
         {loggedIn?
            <div> 
               <NavDropdown.Item href='/account'>MY ACCOUNT</NavDropdown.Item>
               <NavDropdown.Item onClick={logout}>LOGOUT</NavDropdown.Item>
            </div>:
            ""}
         {checkRole([2,3])? 
            <NavDropdown.Item href='/nominations'>NOMINATIONS</NavDropdown.Item>: ""}
      </NavDropdown>

      {modal ? <div 
            id="login-modal-background" 
            onMouseDown={e => { 
               if (e.target === e.currentTarget) { 
                  updateModal(!modal);
               } 
            }}>
            {/* Make sure to close the modal on a background click ^^ */}
            <div className="login-modal-main">
               {/* The main "close" button. */}
               <span 
                  className="login-modal-close btn btn-primary" 
                  onClick={() => updateModal(!modal)}>
                  X
               </span>
               {state.toggleSide ?
               <Form onSubmit={submitForm}>
                  <LoginForm form={state} handleForm={handleForm} />
               </Form>: 
               <ForgotPassword form={state} handleForm={handleForm}/>}
            </div>
         </div>
         : ""}
   </div>
}

export default Login
