import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './BigTreeFooter.css';

class PacificIslandsFooter extends React.Component {
   render() {
      return (
         <Container className="pacific-islands-footer footer" fluid>
            <Container className='p-4'>
               <hr style={{padding: "2rem 2rem"}}className="mt-5 mb-5" />
               <Row>
                  <Col lg={6} xs={12} className="footer-images mb-4">
                     <Row>
                        <Col md={{ span: 3, offset: 0 }} xs={{ span: 4, offset: 1 }} >
                           <Image className="footer-img" src="/images/logos/CAL-FIRE-Logo.png" />
                        </Col>
                        <Col md={{ span: 3, offset: 0 }} xs={{ span: 4, offset: 2 }}>
                           <Image className="footer-img" src="/images/logos/US_Forest_Service.png" />
                        </Col>
                        <Col md={{ span: 3, offset: 0 }} xs={{ span: 4, offset: 1 }}>
                           <Image className="footer-img" src="/images/logos/DLNR_color.jpg" />
                        </Col>
                        <Col md={{ span: 3, offset: 0 }} xs={{ span: 4, offset: 2 }}>
                           <Image className="footer-img" src="/images/logos/DOFAW_logo.png" />
                        </Col>
                     </Row>
                  </Col>
                  <Col lg={6} xs={12}>
                     <Image className="footer-img float-right" src="/images/logos/calpoly-logo.png" />
                  </Col>
               </Row>
               <p className="p-4">No warranties or guarantees as to the accuracy of the data and information derived from this web site are expressed or implied. The California Polytechnic State University and the Cal Poly Corporation shall not be responsible for any loss of profit, indirect, incidental, special, or consequential damages arising out of the use of the data and information derived from this web site.</p>

            </Container>
         </Container>
      )
   }
}

export default PacificIslandsFooter;
