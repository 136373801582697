import React from 'react';
import { Col, Alert } from 'react-bootstrap';
import './Counter.css';
import { useSelector } from "react-redux";
import CountUp from 'react-countup';

function MapCounter(props) {

   const pageResults = useSelector(state => state.btData.searchResults.pageResults);
   const count = pageResults.filter( t => t.latitude !== 0 && t.longitude !== 0).length;

   return <Col>
         <h3 className="result-counter-counter">
            <span className="count">
               <CountUp end={count}/>
            </span> TREES
         </h3>
      </Col>
}

export default MapCounter;
