import React from "react";
import "./BtSearchByName.css";

import { Transition } from 'react-transition-group';
import BtSearchByNameSuggestionCard from "./BtSearchByNameSuggestionCard";
import uuid from 'react-uuid';

class BtSearchByNameSuggestionBox extends React.Component {
  constructor(props) {
    super();
    this.state = {}
  }


  renderSuggestions = () => {
    try {
      let filteredSuggestions = this.props.parentComponent.filterSuggestions()

      if (filteredSuggestions && filteredSuggestions.length > 0) {
        return <>
          {filteredSuggestions.map((suggestion, i) => <BtSearchByNameSuggestionCard key={uuid()} suggestion={suggestion} i={i} {...this.props} />)}
        </>
      } else {
        return <div className="mb-2 mt-1">
          No search results.
        </div>
      }
    } catch (err) {
      console.log(`components.SearchByNameSuggestionBox.renderSuggestions: ${err}`);
      return;
    }

  }

  render() {
    //The next few variables all set & define the transition, using react-transition-group.
    const animationDuration = 200;
    const animationDefaultStyle = {
      transition: `opacity ${animationDuration}ms ease-in-out, max-height ${animationDuration}ms ease-in-out`,
      opacity: 0,
      maxHeight: '0vh'
    }
    const animationTransitionStyles = {
      entering: { opacity: 1, maxHeight: '50vh' },
      entered: { opacity: 1, maxHeight: '50vh' },
      exiting: { opacity: 0, maxHeight: '0vh' },
      exited: { opacity: 0, maxHeight: '0vh' },
    };

    // React-transition-group component- takes in the "showSuggestions" boolean in state to determine whether or not to show.
    return <Transition in={this.props.parentComponent.state.showSuggestions} timeout={animationDuration}>
      {state => (
        <div style={{
          ...animationDefaultStyle,
          ...animationTransitionStyles[state]
        }} className="search-by-name-suggestion-box" >

          {this.renderSuggestions()}

        </div>
      )}
    </Transition>
  }

}

export default BtSearchByNameSuggestionBox
