import { NUMBER_OF_RESULTS, DEFAULT_FILTER_OPTIONS } from './constants'
import {useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

// Session Storage
export const getSearchOptions = () => {
    try {

        if (sessionStorage.ufeiSearchOptions) {
            return JSON.parse(sessionStorage.ufeiSearchOptions);
        } else {
            return null;
        }
    } catch (err) {
        console.log(`utilities.sessionStorage.getSearchOptions: ${err}`);
    }
}

export function useSaveState(searchTerm) {
   const btState = useSelector(state => state.btData);

   useEffect(() => {
      //TODO FIND better conditions for setting to only set once data is valid
      if(typeof btState === 'object' && btState !== null && !Array.isArray(btState)) {
         sessionStorage.btState = JSON.stringify({data: btState, searchTerm: searchTerm});
      }

   }, [btState]);
}

export function useLoadState() {
   const dispatch = useDispatch()
   return (searchTerm) => {
      try {
         const btState = JSON.parse(sessionStorage.btState);
         if(btState !== undefined &&
            btState.data !== undefined && 
            btState.data.searchResults !== undefined && 
            btState.searchTerm === searchTerm) {
            dispatch({type: 'SET_BT_DATA', payload: btState.data})
            return true;
         }

         return false;

      } catch (err) {
         console.log(`utilities.sessionStorage.loadState: ${err}`);
         return false;
      }
   }
}

export const setSearchPageOptions = (searchOptions = null) => {
    // set session storage options as JSON
    try {
        if (!sessionStorage.ufeiSearchOptions) {
            if (!searchOptions) {
                searchOptions = {
                    activePage: 1,
                    resultsPerPage: NUMBER_OF_RESULTS[0],
                    scroll_y: 0,
                    sort: 1
                };
            }
            sessionStorage.ufeiSearchOptions = JSON.stringify(searchOptions);
        } else {
            if (searchOptions) {
                sessionStorage.ufeiSearchOptions = JSON.stringify(searchOptions);
            }
        }

    } catch (err) {
        console.log(`utilities.sessionStorage.setSearchPageOptions: ${err}`);
    }
}

// Filter Session
export const getFilterOptions = () => {
    try {
        if (sessionStorage.ufeiFilterOptions) {
            return JSON.parse(sessionStorage.ufeiFilterOptions);
        }
    } catch (err) {
        console.log(`utilities.sessionStorage.getFilterOptions: ${err}`);
    }
}

export const setFilterOptions = (filterOptions = null) => {
    // set session storage options as JSON
    try {
        if (!sessionStorage.ufeiFilterOptions) {
            if (!filterOptions) {
                filterOptions = DEFAULT_FILTER_OPTIONS();
            }
            sessionStorage.ufeiFilterOptions = JSON.stringify(filterOptions);
        } else {
            if (filterOptions) {
                sessionStorage.ufeiFilterOptions = JSON.stringify(filterOptions);
            }
        }

    } catch (err) {
        console.log(`utilities.sessionStorage.setFilterOptions: ${err}`);
    }
}

export const resetFilterOptions = () => {

    let resetOptions = DEFAULT_FILTER_OPTIONS()
    delete resetOptions.region
    sessionStorage.ufeiFilterOptions = JSON.stringify(resetOptions);
}


// May not need this
export const translateFilterOptions = (filterOptions) => {
    try {
        let url = Object.entries(filterOptions)
            .filter((pair) => (!Array.isArray(pair[1]) || (Array.isArray(pair[1]) && pair[1].length > 0)) && pair[1] !== "")
            .map((pair) => pair[0] + "=" + (Array.isArray(pair[1]) ? pair[1].join(',') : pair[1])).join("?");
        return url;
    } catch (err) {
        console.log(`utilities.sessionStorage.translateFilterOptions: ${err}`)
    }
}
