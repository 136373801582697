const DEFAULT_SEARCH_SETTINGS = {
   activePage: 1,
   resultsPerPage: 12,
   sort: 1,
   mapToggle: true,
   satelliteView: false
}

export default (state = {searchSettings: DEFAULT_SEARCH_SETTINGS}, action) => {
   switch (action.type) {
      case "SET_COUNT":
         return { ...state, btCount: action.payload.btCount, champCount: action.payload.champCount }
      case "SET_SEARCH_RESULTS":
         return { ...state, searchResults: action.payload }
      case "SET_SEARCH_SETTINGS":
         return { ...state, searchSettings: action.payload }
      case "SET_BT_DETAIL":
         return { ...state, btDetail: action.payload }
      case "SET_BT_DATA":
         return {...state, ...action.payload};
      default:
      return state;
   }
}
