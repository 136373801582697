import React from 'react';
import { Card } from 'react-bootstrap';
import htmlParser from 'react-html-parser';
import './BtResultCard.css';


class BtResultCard extends React.Component {
    renderCard = () => {
        try {
            const image_prefix = process.env.REACT_APP_BASE_IMAGE_URL;
            return (
                <Card className="result-card" >
                    <div className="p-1">
                        <Card.Title className="label-demibold">{this.props.commonName}</Card.Title>
                        <Card.Subtitle className="text-muted font-italic result-card-scientific-name">{htmlParser(this.props.scientificName)}</Card.Subtitle>
                    </div>
                    <div className="result-card-img-container">
                        <Card.Img variant="bottom" className='result-card-img'
                            src={this.props.imgLink ? `${image_prefix}${this.props.imgLink}` : "/images/default-tree.png"}
                            onError={(evt) => {

                                evt.target.onerror = null;
                                evt.target.src = "/images/default-tree.png"

                            }} />


                        <div className="result-card-icons">

                            {this.props.lowZone === 1 && this.props.height <= 25 ?
                                <img src="/images/icons/util_square_ico.png" alt='' /> : ""}

                        </div>
                    </div>
                </Card >
            )
        } catch (err) {
            console.log(`components.ResultCard.renderCard: ${err}`);
            return null;
        }
    }

    render() {
        return (
            <div data-tree-id={this.props.treeId}>
                {this.renderCard()}
            </div>
        )
    }
}

export default React.memo(BtResultCard);

