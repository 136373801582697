import React from 'react';
import { Col, Alert } from 'react-bootstrap';
import './Counter.css';
import { useSelector } from "react-redux";
import CountUp from 'react-countup';

function ResultCounter(props) {

 const count = useSelector(state => state.btData.searchResults.totalResults);

   function renderAlert() {
      return <Alert variant="success">
         <Alert.Heading>We couldn't resolve that link</Alert.Heading>
         <p>
            Do you mean any of the trees below? Be sure to update your bookmark!
         </p>
      </Alert>
   }

   return <Col>
         <h3 className="result-counter-counter">
            <span style={{textAlign: 'left'}} className="count">
               <CountUp end={count}/>
            </span> TREES
         </h3>
      {/*renderAlert()*/}
      </Col>

}
export default ResultCounter;
