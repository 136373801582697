import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Route, Switch } from 'react-router-dom';
import { checkRole } from '../../utilities/user'

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "../../reducers/reducers";
import { BrowserRouter } from 'react-router-dom';
// for Chrome Redux Devtools Extension
import { composeWithDevTools } from "redux-devtools-extension";

// COMPONENTS
import BigTree from '../../components/BigTree/BigTree';
import BtResult from '../../components/BtResult/BtResult';

import BtTreeDetail from '../../components/BtTreeDetail/BtTreeDetail';
import EditTree from '../../components/TreeForm/EditTree';

import Help from '../../components/StaticPages/Help/Help';
import HowToMeasure from '../../components/StaticPages/HowToMeasure/HowToMeasure';
import Nominate from '../../components/Nominate/Nominate';
import NominationList from '../../components/Nominate/NominationList';

import BigTreeFooter from '../../components/BigTreeFooter/BigTreeFooter';
import BigTreeHeader from '../../components/BigTreeHeader/BigTreeHeader';

import UserAccount from '../UserAccount/UserAccount';
import UserAccountEdit from '../UserAccountEdit/UserAccountEdit';
import UserAccountInvite from '../UserAccountInvite/UserAccountInvite';
import UserAccounts from '../UserAccounts/UserAccounts';

import EditNomination from '../Nominate/EditNomination'

import BtAllMap from '../BtAllMap/BtAllMap'

// error components
import NotFound from "../../components/NotFound/NotFound";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
// import sessionStorage for building sessionStorage objects
import { getSearchOptions, setSearchOptions }
  from "../../utilities/btSessionStorage";
// global css styles
import "./App.css";

const store = createStore(
   reducers,
   process.env.REACT_APP_ENV !== 'prod'?
      composeWithDevTools(applyMiddleware(thunk)):
      compose(applyMiddleware(thunk))
);

const App = () => {
  // set sessionStorage objects
  useEffect(() => {
    if (!getSearchOptions()) {
      setSearchOptions();
    }
  }, [])

  const loggedIn = localStorage.getItem('loggedIn')
  return (

    <Provider store={store}>
      <BrowserRouter>
        <Container className="app-container" fluid>
          <BigTreeHeader/>
          <Switch>
            <Route exact path="/" component={BigTree} />

            {/* <Route exact path="/search-results/name-search/:searchTerm" component={ResultNameSearch} /> */}
            <Route exact path="/search-results" component={BtResult} />

            <Route exact path="/bt-tree-detail/:treeName" component={BtTreeDetail} />


            <Route exact path="/help" component={Help} />
            <Route exact path="/how-to-measure-a-tree" component={HowToMeasure} />
            <Route exact path="/nominate" component={Nominate} />

            {loggedIn ?
              <>
                <Route exact path="/account" component={UserAccount} />
                <Route exact path="/account/edit" component={UserAccountEdit} />

                {
                  checkRole([2, 3]) ? <>
                    <Route exact path="/nominations" component={NominationList}/>
                    <Route exact path="/edit/nomination/:nom_id" component={EditNomination} />
                  </> : ""
                }

                {
                  checkRole([3]) ? <>
                    <Route exact path="/account/invite" component={UserAccountInvite} />
                    <Route exact path="/account/users" component={UserAccounts} />
                  </> : ""
                }
              </>
              : ""}

            <Route exact path="/error" component={ErrorPage} />
            <Route component={NotFound} />
          </Switch>
          <BigTreeFooter />
        </Container>
      </BrowserRouter>
    </Provider>
  );
};

export default App;


