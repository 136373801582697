import React from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import './BtResultOps.css';
//hooks
import {useSelector, useDispatch } from "react-redux";
// components
import ResultCounter from "../Counter/ResultCounter";
import MapCounter from "../Counter/MapCounter";
import ResultSort from "../ResultSort/ResultSort";
import ResultPagination from '../ResultPagination/ResultPagination';


const BtResultOps = (props) => {

   const dispatch = useDispatch();
   const searchSettings = useSelector(state => state.btData.searchSettings)

   function toggleMap() {
      dispatch({
         type: "SET_SEARCH_SETTINGS", 
         payload: {
            ...searchSettings, 
            mapToggle: !searchSettings.mapToggle
         }
      });
   }


   function toggleMapView() {
      dispatch({
         type: "SET_SEARCH_SETTINGS", 
         payload: {
            ...searchSettings, 
            satelliteView: !searchSettings.satelliteView 
         }
      });
   }

   function MapOps() {
      return <>
         <MapCounter/>

         <Col className="d-inline col-auto">
            <Button onClick={()=> toggleMapView()}>
               {searchSettings.satelliteView? 'Normal View': 'Satellite View'}
            </Button>
         </Col>
         <Col className="d-inline col-auto">
            <Button onClick={()=> toggleMap()}>
               See Gallery
            </Button>
         </Col>
      </>
   }

   function GalleryOps() {
      return <>
         <ResultCounter />

         <Col lg={{ span: 3 }} className="d-inline equal-height-columns-2 ">
            <ResultSort />
         </Col>
         <Col lg={{ span: 3 }} className="d-inline equal-height-columns-2 result-ops-pagination-container">
            <ResultPagination />
         </Col>
         <Col className="d-inline col-auto">
            <Button onClick={()=> toggleMap()}>
               See Map
            </Button>
         </Col>
      </>
   }

   return (

      <Container className="result-ops pt-2 pb-2 pl-4 pr-4">
         <Row className='result-ops-sort'>
            {searchSettings.mapToggle? MapOps(): GalleryOps() }
         </Row>
         <hr />
      </Container>
   )
}

export default BtResultOps;
