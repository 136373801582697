import React from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom'

import api from "../../apis/selectree_api";
import { auth } from "../../utilities/header"

import UserAccountRow from './UserAccountRow'


class UserAccounts extends React.Component {

    constructor(props) {
        super();

        this.state = {
            users: [],
            search: "",
            roles: []

        }
    }

    componentDidMount = () => {
        this.getUsers()
        this.getRoles()
    }

    componentDidUpdate = () => {
        if (this.state.forceData) {
            this.setState({ forceData: false })
            this.getUsers()
        }
    }

    getUsers = async () => {

        try {

            let res = await api.get(`/users`, auth);
            console.log(res)
            this.setState({ users: res.data })

        } catch (err) {
            console.log(`Error in components.UserAccounts.getUsers: ${err}`);
        }
    }
    getRoles = async () => {
        try {

            let res = await api.get(`/roles`, auth);
            console.log(res)
            this.setState({ roles: res.data })

        } catch (err) {
            console.log(`Error in components.UserAccounts.getUsers: ${err}`);
        }
    }

    changeSearch = async (e) =>{
        this.setState({search: e.target.value})
    }

    submitForm = async (e) => {
        e.preventDefault()
        try {
            let res = await api.post(`/users/search`, {search: this.state.search}, auth);
            console.log(res)
            this.setState({ users: res.data })

        } catch (err) {
            console.log(`Error in components.UserAccounts.getUsers: ${err}`);
        }
    }


    render() {
        return (
            <div className="container pt-4 pb-4" >
                <h1 className="mb-2">View User Accounts</h1>

                <Link to="/account" className="form-button-secondary mb-2" >Back</Link>

                <div className="mb-4">
                    <Container>

                        <Form onSubmit={this.submitForm}>

                            <Form.Group className="form-field-text-inline">
                                <Form.Label>Search by Email:</Form.Label>
                                <input type="text" className="form-field-plain-text" value={this.state.search} onChange={this.changeSearch} />
                                <Button type="submit">Search</Button>
                            </Form.Group>

                            {this.state.error ? <div>{this.state.error}</div> : ""}


                        </Form>

                    </Container>
                    <Container>

                        <Row>
                            <Col lg={3}>
                                Email
                            </Col>
                            <Col lg={3}>
                                Name
                            </Col>
                            <Col lg={3}>
                                Organization
                            </Col>
                            <Col lg={3}>
                                Role
                            </Col>
                        </Row>

                        {this.state.users.map((user) =>
                            <UserAccountRow user={user} parentComponent={this} roles={this.state.roles} />
                        )}

                    </Container>
                </div>
            </div>
        )
    }
}

export default UserAccounts;