//The number of different pages to display in the pagination.
export const PAGES_TO_DISPLAY = 4

//Array holding the number of pages you can select for the results
export const NUMBER_OF_RESULTS = [12, 24, 36]

export const DEFAULT_FILTER_OPTIONS = () => {
    return {
        minTreeHeight: 0,
        maxTreeHeight: 100,
        fall_color: [],
        ca_native: [],
        hi_native: [],
        tree_shape: 0,
        foliage_type: 0,
        leaf_form: 0,
        leaf_arrangement: 0,
        flower_color: [],
        fruit_type: [],
        fragrance: [],
        usda_zones: [],
        sunset_climate_zone: [],
        deer_tolerance: [],
        salt_tolerance: [],
        water_use_rating: [],
        sun_exposure: [],
        planting_area: 0,
        utility_precautions: [],
        searchTerm: "",
        powerline_friendly: []
    }
}




export const DEFAULT_TREE_FIELDS = () => {
    return {
        display: "0",
        pacific_island: 0,

        common_name: "",
        other_common_name: [],

        family: "",

        memo: '<p></p>',
        generic: [],

        has_redirect: 0,
        redirect_tree: [],
        redirect_tree_id: 0,

        photolocations: "",
        images: [],

        has_fragrance: [],

        pi_invasive: [],
        ca_invasive: [],
        invasive_text: "",

        landscape_application: [],
        landscape_use: [],

        usda_zone: [],
        sunset_zone: [],

        height_high: "",
        height_low: "",
        width_high: "",
        width_low: "",
        growth_rate_high: "",
        growth_rate_low: "",


        exposure_low: 0,
        exposure_high: 0,
        soil_ph_low: [],
        soil_ph_high: [],

        soil_texture: [],

        california_native: 0,
        
        tree_shape: [],
        litter_type: [],

        bark_texture: [],
        bark_color: 0,

        foliage_growth_color: [],
        foliage_fall_color: 0,
        foliage_type_low: 0,
        foliage_type_high: 0,

        leaf_form: 0,
        leaf_arrangement: 0,
        leaflet_shape: 0,

        flower_color: [],
        flower_time: [],
        flower_fragrance: 0,
        flower_showiness: 0,
        sex: 0,

        fruit_type: [],
        fruiting_time: [],
        fruit_value: [],
        fruit_color: 0,
        fruit_size: 0,

        branch_strength_high: 0,
        branch_strength_low: 0,

        fragrance: [],
        salinity_tolerance: [],
        water_use: [],
        saltspray_tolerance: [],

        native_range: "",

        planting_area: 0,
        utility_precautions: [],
        searchTerm: "",
        powerline_friendly: [],

        //Considerations
        pest_resistant: [],
        pest_susceptibility: [],
        disease_resistant: [],
        disease_susceptibility: [],

        root_damage_potential: [],
        health_hazard: [],

        deer_palatable: 0,
        biogenic_emissions: [],
        attracts_wildlife: [],
    }
}

export const COUNTY_OPTIONS = [
      { value: 'Alameda', label: 'Alameda' },
      { value: 'Alpine', label: 'Alpine' },
      { value: 'Amador', label: 'Amador' },
      { value: 'Butte', label: 'Butte' },
      { value: 'Calaveras', label: 'Calaveras' },
      { value: 'Colusa', label: 'Colusa' },
      { value: 'Contra Costa', label: 'Contra Costa' },
      { value: 'Del Norte', label: 'Del Norte' },
      { value: 'El Dorado', label: 'El Dorado' },
      { value: 'Fresno', label: 'Fresno' },
      { value: 'Glenn', label: 'Glenn' },
      { value: 'Humboldt', label: 'Humboldt' },
      { value: 'Imperial', label: 'Imperial' },
      { value: 'Inyo', label: 'Inyo' },
      { value: 'Kern', label: 'Kern' },
      { value: 'Kings', label: 'Kings' },
      { value: 'Lake', label: 'Lake' },
      { value: 'Lassen', label: 'Lassen' },
      { value: 'Los Angeles', label: 'Los Angeles' },
      { value: 'Madera', label: 'Madera' },
      { value: 'Marin', label: 'Marin' },
      { value: 'Mariposa', label: 'Mariposa' },
      { value: 'Mendocino', label: 'Mendocino' },
      { value: 'Merced', label: 'Merced' },
      { value: 'Modoc', label: 'Modoc' },
      { value: 'Mono', label: 'Mono' },
      { value: 'Monterey', label: 'Monterey' },
      { value: 'Napa', label: 'Napa' },
      { value: 'Nevada', label: 'Nevada' },
      { value: 'Orange', label: 'Orange' },
      { value: 'Placer', label: 'Placer' },
      { value: 'Plumas', label: 'Plumas' },
      { value: 'Riverside', label: 'Riverside' },
      { value: 'Sacramento', label: 'Sacramento' },
      { value: 'San Benito', label: 'San Benito' },
      { value: 'San Bernardino', label: 'San Bernardino' },
      { value: 'San Diego', label: 'San Diego' },
      { value: 'San Francisco', label: 'San Francisco' },
      { value: 'San Joaquin', label: 'San Joaquin' },
      { value: 'San Luis Opispo', label: 'San Luis Opispo' },
      { value: 'San Mateo', label: 'San Mateo' },
      { value: 'Santa Barbara', label: 'Santa Barbara' },
      { value: 'Santa Clara', label: 'Santa Clara' },
      { value: 'Santa Cruz', label: 'Santa Cruz' },
      { value: 'Shasta', label: 'Shasta' },
      { value: 'Sierra', label: 'Sierra' },
      { value: 'Siskiyou', label: ' Siskiyou' },
      { value: 'Solano', label: ' Solano' },
      { value: 'Sonoma', label: ' Sonoma' },
      { value: 'Stanislaus', label: ' Stanislaus' },
      { value: 'Sutter', label: ' Sutter' },
      { value: 'Tehama', label: ' Tehama' },
      { value: 'Toulumne', label: ' Toulumne' },
      { value: 'Trinity', label: ' Trinity' },
      { value: 'Tulare', label: ' Tulare' },
      { value: 'Ventura', label: ' Ventura' },
      { value: 'Yolo', label: ' Yolo' },
      { value: 'Yuba', label: ' Yuba' }
]

export const STATE_OPTIONS = [
      { value: 'AL', label: 'AL' },
      { value: 'AK', label: 'AK' },
      { value: 'AS', label: 'AS' },
      { value: 'AZ', label: 'AZ' },
      { value: 'AR', label: 'AR' },
      { value: 'CA', label: 'CA' },
      { value: 'CO', label: 'CO' },
      { value: 'CT', label: 'CT' },
      { value: 'DE', label: 'DE' },
      { value: 'DC', label: 'DC' },
      { value: 'FL', label: 'FL' },
      { value: 'GA', label: 'GA' },
      { value: 'GU', label: 'GU' },
      { value: 'HI', label: 'HI' },
      { value: 'ID', label: 'ID' },
      { value: 'IL', label: 'IL' },
      { value: 'IN', label: 'IN' },
      { value: 'IA', label: 'IA' },
      { value: 'KS', label: 'KS' },
      { value: 'KY', label: 'KY' },
      { value: 'LA', label: 'LA' },
      { value: 'ME', label: 'ME' },
      { value: 'MD', label: 'MD' },
      { value: 'MA', label: 'MA' },
      { value: 'MI', label: 'MI' },
      { value: 'MN', label: 'MN' },
      { value: 'MS', label: 'MS' },
      { value: 'MO', label: 'MO' },
      { value: 'MT', label: 'MT' },
      { value: 'NE', label: 'NE' },
      { value: 'NV', label: 'NV' },
      { value: 'NH', label: 'NH' },
      { value: 'NJ', label: 'NJ' },
      { value: 'NM', label: 'NM' },
      { value: 'NY', label: 'NY' },
      { value: 'NC', label: 'NC' },
      { value: 'ND', label: 'ND' },
      { value: 'MP', label: 'MP' },
      { value: 'OH', label: 'OH' },
      { value: 'OK', label: 'OK' },
      { value: 'OR', label: 'OR' },
      { value: 'PA', label: 'PA' },
      { value: 'PR', label: 'PR' },
      { value: 'RI', label: 'RI' },
      { value: 'SC', label: 'SC' },
      { value: 'SD', label: 'SD' },
      { value: 'TN', label: 'TN' },
      { value: 'TX', label: 'TX' },
      { value: 'UT', label: 'UT' },
      { value: 'VT', label: 'VT' },
      { value: 'VA', label: 'VA' },
      { value: 'VI', label: 'VI' },
      { value: 'WA', label: 'WA' },
      { value: 'WV', label: 'WV' },
      { value: 'WI', label: 'WI' },
]
