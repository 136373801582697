import React, { useEffect, useState } from 'react'
import { ImCheckboxChecked } from 'react-icons/im';

import './FormField.css'

/* 
 * name: attribute checkbox value is being assigned
 * handleForm: (name, value) -> {state..., name: value}
 * options: {label: "checkbox text", value: associated value}
 */

function MultiCheckBox(props) {
   const {name, defaultValue, handleForm, options} = props
   const [value, setValue] = useState("");

   return(
      <ul>
         {options.map(option => {
            return (
               <div className="form-check form-check-inline" key={option.label}>
                  <input
                     className="form-check-input"
                     type="checkbox"
                     id={option.label}
                     checked={value === "" ? defaultValue === option.value : value === option.value}
                     onChange={e => {
                        if(option.value === value || option.value === defaultValue) {
                           setValue(undefined);
                           handleForm(name, undefined)
                        } else {
                           setValue(option.value)
                           handleForm(name, option.value)
                        }
                     }}
                     />
                  <span className="overlay">
                     <ImCheckboxChecked className="icon" />
                  </span>
                  <label className="form-check-label">{option.label}</label>
               </div>)})}
      </ul>)
}

export default MultiCheckBox;
