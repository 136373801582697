import React from "react";
import { Form } from "react-bootstrap";
import { ImCheckboxChecked } from 'react-icons/im';
import "./FormField.css"
import { FaTrashAlt } from "react-icons/fa";


const arrayEquality = (a1, a2) => {
    var i = a1.length;
    while (i--) {
        if (a1[i] !== a2[i]) return false;
    }
    return true
}

var updateTimeout = null;

class FormFieldPrimaryText extends React.Component {
    constructor(props) {
        super(props);

        //PrimaryText takes in the field, like other FormField components, but there also must be
        //an array that follows the naming convention 'other_{field}' that are the "secondary" values
        if (props.parentComponent.state) {
            let value = props.parentComponent.state[props.field]
            if (value === undefined || value === null) {
                console.log(`Components.FormField.FormFieldPrimaryText- Cannot find field ${this.props.field} on the parent component.`)
            }

            let secondaryValue = props.parentComponent.state[`other_${this.props.field}`]
            if (secondaryValue === undefined || secondaryValue === null) {
                console.log(`Components.FormField.FormFieldPrimaryText- Cannot find secondary field other_${this.props.field} on the parent component.`)
            }

            if (!Array.isArray(secondaryValue)) {
                console.log(`Components.FormField.FormFieldPrimaryText- Cannot find secondary field other_${this.props.field} on the parent component.`)
            }

        } else {
            console.log(`Components.FormField.FormFieldPrimaryText- Please pass in the parent component that has the state.`)
        }



        this.state = {
            value: null,
            secondaryValue: [],
            saving: false,
            prevValue: null,
            prevSecondaryValue: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        let newValue = props.parentComponent.state[props.field]
        let newSecondaryValue = props.parentComponent.state[`other_${props.field}`]

        let secondaryEqual = newSecondaryValue.length === state.prevSecondaryValue.length && arrayEquality(newSecondaryValue, state.prevSecondaryValue)

        if (newValue !== state.prevValue || !secondaryEqual) {
            return { value: newValue, prevValue: state.value, secondaryValue: newSecondaryValue, prevSecondaryValue: state.secondaryValue }
        } else { return {} }
    }

    updateParentState = () => {
        let mainField = this.props.field
        let secondaryField = `other_${this.props.field}`

        this.props.parentComponent.setState({ [mainField]: this.state.value, [secondaryField]: this.state.secondaryValue, changed: true  })
    }

    change = (e) => {
        try {
            clearTimeout(updateTimeout);

            let newValue = e.target.value
            let oldValue = e.target.getAttribute('data-old-value')

            let mainValue = this.state.value
            let secondaryValues = this.state.secondaryValue

            //Slightly differing setStates based on if the main value or secondary value is being edited

            if (oldValue === mainValue) {
                this.setState({ value: newValue, saving: -1 })
            } else {
                let index = secondaryValues.indexOf(oldValue)
                secondaryValues[index] = newValue
                this.setState({ secondaryValue: secondaryValues, saving: index })
            }

            updateTimeout = setTimeout(() => {
                this.setState({ saving: false }, this.updateParentState)
            }, 500);

        } catch (err) {
            console.log(`Components.FormField.FormFieldPrimaryText.change()-  ${err}`)
            return null
        }
    }

    changePrimary = (e) => {
        let value = e.target.getAttribute('data-value')

        if (value !== this.state.value) {
            let mainValue = this.state.value
            let secondaryValues = this.state.secondaryValue

            //filter the chosen value out of the secondary values,
            secondaryValues = secondaryValues.filter((x) => x !== value)
            //and add the old main value into the secondary values
            secondaryValues.push(mainValue)

            //Change the main value to the incoming value.
            this.setState({ value: value, secondaryValue: secondaryValues }, this.updateParentState)
        }
    }

    addNew = () => {
        let secondaryValues = this.state.secondaryValue

        //See how many blank entries there are.
        let blankEntries = secondaryValues.filter((x) => x === "")

        //If there are 0 blanks, add in a new one.
        if (blankEntries.length === 0) {
            secondaryValues.push("")
            this.setState({ secondaryValue: secondaryValues }, this.updateParentState)
        }
    }

    deleteEntry = (e) => {
        let value = e.currentTarget.getAttribute('data-value')

        //If they are deleteing the main value,
        if (value === this.state.value) {

            let secondaryValues = this.state.secondaryValue

            //And there is a secondary value to take it's place,
            if (secondaryValues.length > 0) {
                //Splice the first secondary value from it's array and make it the new main value
                let newMain = secondaryValues.splice(0, 1)
                this.setState({ value: newMain[0], secondaryValue: secondaryValues}, this.updateParentState)
            }

        }
        //Otherwise, just delete the value by filtering it out.
        else {
            let secondaryValues = this.state.secondaryValue
            secondaryValues = secondaryValues.filter((x) => x !== value)

            this.setState({ secondaryValue: secondaryValues }, this.updateParentState)
        }

    }

    renderOption = (value, i) => {
        return <div style={{ display: 'flex', alignItems: 'center' }} key={i} >
            <input className="form-field-plain-text" value={value} onChange={this.change} data-old-value={value} style={{ color: this.state.saving === i ? "grey" : "black" }} />

            &nbsp;

            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    id={this.props.field}
                    type="checkbox"
                    checked={value === this.state.value}
                    onChange={this.changePrimary}
                    data-value={value}
                />

                <span className="overlay">
                    <ImCheckboxChecked className="icon" />
                </span>
            </div>

            &nbsp;

            {
                //Only show the delete option if there's any secondary values. DO NOT allow delete on the last value.
                this.state.secondaryValue?.length > 0 ?
                    <span onClick={this.deleteEntry} data-value={value} style={{ cursor: 'pointer' }}><FaTrashAlt /></span>
                    : ""
            }
        </div>
    }

    render() {
        let primaryValue = this.state.value
        let otherValues = this.state.secondaryValue

        return (
            <Form.Group className="form-field-text">
                <Form.Label>{this.props.title}</Form.Label>

                {/* Render the primary value */}
                {this.renderOption(primaryValue, -1)}
                {/* Then the secondary values */}
                {otherValues.map((otherValue, i) => this.renderOption(otherValue, i))}
                {/* Then the add button */}
                <span onClick={this.addNew} className="form-button-primary" style={{ marginTop: '.5rem' }}>+ Add New</span>

            </Form.Group>
        )
    }
}



export default FormFieldPrimaryText