import React, { useEffect, useState } from 'react'
import MenuInput from './MenuInput';
import api from '../../apis/selectree_api';

function cleanInput(str) {
   var cleanString = "";
   if(str !== null && str !== undefined) {
      cleanString = str.replace(/<\/?[^>]+(>|$)/g, "");
   }

   return cleanString;
}

const formatOptionLabel = ({ value, label, common, accepted_common, scientific_name, match}) => (
   <div>
  <div style={{ display: "flex" }}>
    <div><em>{label}</em></div>
    <div style={{ marginLeft: "10px"}}>
      <small>{accepted_common}</small>
    </div>
  </div>
      <small>
    {common !== accepted_common && match === "com"?<div>AKA: <small>{common}</small></div>: ""}
    {label !== scientific_name && match === "sci"?<div>Synonym for: <em>{scientific_name}</em></div>: ""}
      </small>
   </div>
);

function TreeSelect(props) {
   const {options, defaultTree, ...inputProps} = props;
   const [treeOptions, setTreeOptions] = useState([])
   const [searchTerm, updateSearchTerm] = useState("")
   const MIN_INPUT_LENGTH = 3;

   useEffect(() => {
     getTreeOptions();
   },[])

   async function getTreeOptions() {
      const res = await api.get("tree/search-by-name?searchString=&region=all");
      const trees = res.data;
      const opts = [];

      const get_accepted_names = id => {
         const c1 = trees.find((f) => f.tree_id === id && f.c_sequence === 1);

         return { 
            accepted_common: c1 === undefined? c1 :c1.common, 
            label: c1 === undefined? c1 :cleanInput(c1.name_concat)
         };
      }

      for(let t of trees) {
         const sci_name = cleanInput(t.name_concat)
         const display = t.c_sequence !== 1 || t.t_sequence !== 1? 
            get_accepted_names(t.tree_id): 
            {accepted_common: t.common, label: sci_name}
;
         const r = { 
            ...display,
            scientific_name: sci_name,
            common: t.common,
            family: t.family,
            match: "",
            t_sequence: t.t_sequence,
            c_sequence: t.c_sequence,
            generic_common: cleanInput(t.generic_common),
            redirect: t.redirect,
            value: t.tree_id };
         opts.push(r);
      } 
      setTreeOptions(opts);
   }

   const filterSuggestions = (trees, searchTerm) => {
      try {
         let filteredResults = [];
         let tree_seen = new Set();
         if (searchTerm.length > 2) {
            let term = searchTerm.toLowerCase()
            let termArray = term.split(" ");
            let suggestions = trees || []

            //First, get a list of names that actually match the search term.
            suggestions.forEach((s) => {
               let cleanScientificName = "";
               let cleanCommonName = "";
               let cleanFamilyName = "";
               let cleanGenericCommon = "";
               //Clean up the possible names to search.
               s.match = ""
               if (s.scientific_name) {
                  cleanScientificName = s.scientific_name;
                  cleanScientificName = cleanScientificName.replace('&times ', '');
                  cleanScientificName = cleanScientificName.toLowerCase();
               }
               if (s.common) {
                  cleanCommonName = s.common.toLowerCase();
               }
               if (s.family) {
                  cleanFamilyName = s.family.toLowerCase();
               }
               if (s.generic_common) {
                  cleanGenericCommon = s.generic_common;
                  cleanGenericCommon = cleanGenericCommon.replace('&times ', '');
                  cleanGenericCommon = cleanGenericCommon.toLowerCase();
               }

               //TODO come up with better way of matching this the one use on
               //search bar minus synonym filtering but its a bit finicky
               const match = (r, str) => str.indexOf(term) >= 0 //|| str.split(" ").indexOf(r) >= 0;
               const scientificNameMatch = termArray.some(r => match(r, cleanScientificName));
               const commonNameMatch = termArray.some(r => match(r, cleanCommonName));
               const familyNameMatch = termArray.some(r => match(r, cleanFamilyName));
               const genericCommonMatch = termArray.some(r => match(r, cleanGenericCommon));

               //If the search term matches any of the names, push it into filteredResults[]
               if (scientificNameMatch || commonNameMatch || familyNameMatch || genericCommonMatch) {

                  if(scientificNameMatch)
                     s.match = "sci"

                  else if (commonNameMatch)
                     s.match = "com"

                  filteredResults.push(s);
               }

            });

            filteredResults.sort((a, b) => {
               if(a.t_sequence === b.t_sequence) {
                  return b.c_sequence - a.c_sequence;
               }
               return a.t_sequence - b.t_sequence;
            })

            const distinctFilteredResults = []

            for(let t of filteredResults) {
               if(!tree_seen.has(t.value)) {
                  tree_seen.add(t.value)
                  distinctFilteredResults.push(t)
               }
            }
            
            //only grab first 100 searches for performance
            return distinctFilteredResults.slice(0,100);
         }

      } catch (err) {
         console.log(`components.SearchByNameSuggestionBox.filterSuggestions: ${err}`);
         return;
      }

   }

   const filterOption = (candidate, input) => {
      return (
         // Min input length
         input.length >= MIN_INPUT_LENGTH
            // Use Select's default filtering for string matching by creating filter
            //createFilter({})(candidate, input)
      );
   };

   return (
      <MenuInput
         classNamePrefix='TreeMenu'
         {...inputProps}
         value={
         defaultTree? treeOptions.find(opt => {
            if(opt.value === defaultTree.value && opt.label === defaultTree.label)
            return opt;
         }): undefined}
         options={filterSuggestions(treeOptions, searchTerm)}
         filterOption={filterOption}
         formatOptionLabel={formatOptionLabel}
         onInputChange={(input) => updateSearchTerm(input)}
         />)
}

export default TreeSelect
