import React, { useEffect, useState } from 'react';
import { Col, Container, Pagination, Spinner } from 'react-bootstrap';
import './BtResultGallery.css'
import uuid from 'react-uuid';
import { getSearchOptions, setSearchOptions } from '../../utilities/btSessionStorage';
// actions
// TODO - Get BigTrees specific data. searchResults is where the data needs to post
// import { getSearchResultsPageData, getSearchByNameMultiResult } from '../../actions/search';
import {useSelector, useDispatch } from "react-redux";
// components
import BtResultCard from "../BtResultCard/BtResultCard";

import { PAGES_TO_DISPLAY } from '../../utilities/constants'

const BtResultGallery = () => {
   const btData = useSelector(state => state.btData);
   const searchSettings = btData.searchSettings;
   const {activePage, resultsPerPage} = searchSettings; 
   const searchData = btData.searchResults; 
   const searchResults = searchData.pageResults;
   const totalResults = searchData.totalResults;

   const dispatch = useDispatch();

   const [{ numOfPages }, setNumOfPages] = useState({ numOfPages: null });
   const [{ startPage }, setStartPage] = useState({ startPage: 1 });
   const [{ endPage }, setEndPage] = useState({ endPage: null });

   //TODO figure out if these are needed
   const [imagesReady, setImagesReady] = useState(false);
   const [preloadedImages, setPreloadedImages] = useState(0);
   const [{ loadingImages }, setLoadingImages] = useState({ loadingImages: false });

   function setActivePage(newActivePage) {       
      let newSearchSettings = searchSettings;
      newSearchSettings.activePage = newActivePage;
      dispatch({type: "SET_SEARCH_SETTINGS", payload: newSearchSettings})
   }

   useEffect(() => {
      if (searchResults && searchResults.length) {
         console.log('using effect!');
         setNumOfPagesHelper();
      }
   }, [resultsPerPage, activePage]);

   useEffect(() => {
      if (searchResults && searchResults.length) {
         preloadImages();
      }
   }, []);

   const preloadImages = () => {
      try {
         if (searchResults && searchResults.length && !loadingImages && !imagesReady) {
            const image_prefix = process.env.REACT_APP_BASE_IMAGE_URL;
            for (let e of searchResults) {
               setLoadingImages({ loadingImages: true });
               let img = new Image();
               if (e.photo_link != null) {
                  img.src = `${image_prefix}${e.photo_link}`;
               } else {
                  img.src = "/images/default-tree.png";
               }

               img.onload = () => {
                  setPreloadedImages(preloadedImages + 1);
                  if (preloadedImages >= resultsPerPage || preloadedImages >= searchResults.length) {
                     setImagesReady(true);
                     setPreloadedImages(0);

                  }

               };

               img.onerror = (evt) => {
                  //Make sure to load the backup image if image not found
                  evt.target.onerror = null;
                  evt.target.src = "/images/default-tree.png"

                  console.log(`image not loaded: ${evt.currentTarget.getAttribute('src')}`);
                  //   setPreloadedImages(preloadedImages => preloadedImages + 1)
               };

            }

            setLoadingImages({ loadingImages: false });
         } else {
            console.log(`no searchResults`);
         }

      } catch (err) {
         console.log(`components.BtResultGallery.preloadImages: ${err}`);
         return;
}

   }

   const renderLoading = () => {
      let searchOptions = getSearchOptions();
      if (searchOptions && window.scrollY !== searchOptions.scroll_y) {
         window.scrollTo(0, searchOptions.scroll_y);
}
      return (
         <div style={{ height: 750 }}>
            <br />
            <br />
            <Spinner animation="border" role="status" className="result-gallery-spinner" />
         </div>
      )
   }

   const renderNoResults = () => {
      try {
         let pageHostname = window.location.hostname;
         pageHostname = pageHostname === 'localhost' ? 'http://localhost:3000' : `http://${pageHostname}`;
         return (
            <div className="result-gallery-no-results">
               <h2>No Results Found</h2>
               <p>The search may be too restrictive. Try searching again with fewer options (below)- that will widen the search results.</p>
               <h6><a href={pageHostname}>Return to the BigTree Home</a></h6>
            </div>
         )
      } catch (err) {
         console.log(`components.ResultGallery.renderNoResults: ${err}`);
         return null;
      }
   }

   const calculateStartPage = () => {
      /*
BigTree breaks total results down into pages
And pages into sets of PAGES_TO_DISPLAY length
This function calculates the start page of each set based on 
PAGES_TO_DISPLAY and the current active page
Credit to Lynne West for the formula
const P = activePage;
const D = PAGES_TO_DISPLAY;
return (Math.floor(((P - 1) / D) + 1) * D) - (D - 1);
*/
      return activePage;

   }

   const setNumOfPagesHelper = () => {
      try {
         if (searchResults) {
            const numOfPages = Math.ceil(totalResults / resultsPerPage);
            const newStartPage = calculateStartPage();
            setNumOfPages({ numOfPages: numOfPages });
            setStartPage({ startPage: newStartPage });
            setEndPage({ endPage: numOfPages });
         }

      } catch (err) {
         console.log(`components.ResultGallery.setNumOfPages: ${err}`);
      }

   }

   const ResultGallery = () => {
      try {
         let searchOptions = getSearchOptions();

         /*
         if (searchOptions && window.scrollY !== searchOptions.scroll_y) {
            window.scrollTo(0, searchOptions.scroll_y);
         }
         */

         let firstElem = (activePage - 1) * resultsPerPage;
         let lastElem = (firstElem + resultsPerPage) > searchResults.length? 
            searchResults.length:
            firstElem + resultsPerPage;

         return (
            !totalResults? renderNoResults():
               <div>
                  {searchResults.slice(firstElem, lastElem).map((card) => {
                     return (
                        <Col lg={4} md={6} xs={12}
                           key={uuid()}
                           className="result-gallery-card-container pt-4 pb-0 pl-4 pr-4">
                           <a className='result-gallery-card-link'
                              href={`/bt-tree-detail/${card.bt_id}`}>
                              <BtResultCard
                                 scientificName={card.scientificName}
                                 commonName={card.commonName}
                                 imgLink={card.photo_link}
                                 stId={card.st_id}
                                 btId={card.bt_id}
                                 key={uuid()} />
                           </a>
                        </Col>
                     )
                  })}
               </div>)
      } catch (err) {
         console.log(`components.ResultGallery.renderResultGallery: ${err}`);
         return null;
      }

   }

   const setScrollY = (e) => {
      try {
         let searchOptions = getSearchOptions();
         searchOptions.scroll_y = window.scrollY;
         setSearchOptions(searchOptions);
      } catch (err) {
         console.log(`components.ResultGallery.setScrollY: ${err}`);
      }
   }

   const handlePaginationClick = (e) => {
      try {
         setScrollY();

         const newActivePage = searchSettings.activePage =parseInt(
            e.currentTarget.getAttribute('data-page-number'));

         setImagesReady(false);
         setActivePage(newActivePage);
      } catch (err) {
         console.log(`components.ResultGallery.handlePaginationClick: ${err}`);
      }

   }

   const handleArrowClick = (e) => {
      try {
         setScrollY();
         const clickVal = e.currentTarget.getAttribute('data-page-number');
         const destination = clickVal === 'last' ? endPage : startPage + PAGES_TO_DISPLAY;
         if (destination !== activePage) {
            switch (clickVal) {
               case "first":
                  setImagesReady(false);
                  setStartPage({ startPage: 1 });
                  setActivePage(1);

                  break;
               case "prev":
                  setImagesReady(false);
                  setStartPage({ startPage: startPage - 1 });
                  setActivePage(startPage - 1);

                  break;
               case "next":
                  setImagesReady(false);
                  setStartPage({ startPage: startPage + 1 });
                  setActivePage(startPage + 1);

                  break;
               case "last":
                  setImagesReady(false);
                  setActivePage(endPage);
                  break;
               default:
               console.error(`Error: invalid input in BtResultGallery.handleArrowClick.`);
               break;
            }
         }
      } catch (err) {
         console.log(`components.ResultGallery.handleArrowClick: ${err} `)
      }
   }

   const renderPaginationButtons = () => {
      // startPage is calculated in the setNumOfPages helper
      // Pagination logic: only display the previous and first pagination buttons if the startpage is greater than 1. Only display the ellipses and next and last buttons if the user is "far enough" from the endPage to have that make sense.
      try {
         return (
            numOfPages ?
               <Pagination className="result-gallery-pagination">
                  {startPage > 1 ?
                     <Pagination.First
                        className="box-link"
                        onClick={handleArrowClick}
                        data-page-number={'first'} />:
                     ""}
                  {startPage > 1 ?
                     <Pagination.Prev
                        className="box-link"
                        onClick={handleArrowClick}
                        data-page-number={'prev'} />:
                     ""}
                  {Array.from(Array(startPage + PAGES_TO_DISPLAY - 1).keys()).map(pageNum => {
                     pageNum++;
                     return (
                        pageNum >= startPage && pageNum <= endPage ?
                           <Pagination.Item 
                              key={uuid()} 
                              active={activePage === pageNum} 
                              onClick={handlePaginationClick} 
                              data-page-number={pageNum}>{pageNum}</Pagination.Item>:
                           "");
                  })}

                  {
                  endPage - startPage > PAGES_TO_DISPLAY  ?
                     <>
                        <Pagination.Ellipsis />
                        <Pagination.Item
                           key={uuid()}
                           active={activePage === endPage}
                           onClick={handlePaginationClick}
                           data-page-number={endPage}>
                           {endPage}
                        </Pagination.Item>
                        <Pagination.Next
                           className="box-link"
                           onClick={handleArrowClick}
                           data-page-number={'next'} />
                        <Pagination.Last
                           className="box-link"
                           onClick={handleArrowClick}
                           data-page-number={'last'} />
                        </>:
                     ""
               }
               </Pagination>:
               null
         )
      }
      catch (err) {
         console.log(`components.ResultGallery.renderPaginationButtons: ${err} `);
         return null;
      }
   }

   return (
      <Container className="result-gallery mb-5">
         {renderPaginationButtons()}
         {ResultGallery()}
         <br />
         {renderPaginationButtons()}
      </Container >
   )

}

export default BtResultGallery;
