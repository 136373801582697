import { NUMBER_OF_RESULTS } from './constants'

// Session Storage
export const getSearchOptions = () => {
    try {
        if (sessionStorage.ufeiBtSearchOptions) {
            return JSON.parse(sessionStorage.ufeiBtSearchOptions);
        } else {
            return null;
        }
    } catch (err) {
        console.log(`utilities.btSessionStorage.getSearchOptions: ${err}`);
    }
}

export const setSearchOptions = (searchOptions = null) => {
    // set session storage options as JSON
    try {
        if (!sessionStorage.ufeiBtSearchOptions) {
            if (!searchOptions) {
                searchOptions = {
                    activePage: 1,
                    resultsPerPage: NUMBER_OF_RESULTS[0],
                    scroll_y: 0,
                    sort: 1
                };
            }
            sessionStorage.ufeiBtSearchOptions = JSON.stringify(searchOptions);
        } else {
            if (searchOptions) {
                sessionStorage.ufeiBtSearchOptions = JSON.stringify(searchOptions);
            }
        }

    } catch (err) {
        console.log(`utilities.btSessionStorage.setSearchPageOptions: ${err}`);
    }
}
