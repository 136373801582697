import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { FaCaretRight, FaCaretDown } from "react-icons/fa";
import './ResponsiveAccordion.css'

class SearchByCharAccordion extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }

    }

    toggleIcon = () => {
        this.setState({ open: !this.state.open})
    }

    render() {
        return (
            <Accordion className="responsive-accordion">
                <Card>
                    <Accordion.Toggle onClick={this.toggleIcon} as={Card.Header} eventKey="0" className="responsive-accordion-title panel-title">
                        <h3 className="category-label">
                            {this.props.title}
                            {this.state.open ? <FaCaretDown /> : <FaCaretRight /> }                       
                        </h3>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="responsive-accordion-collapse">
                        <Card.Body className="bg-secondary">
                            {this.props.children}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    }
}

export default SearchByCharAccordion