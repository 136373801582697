import api from "../apis/selectree_api";
import { auth } from "../utilities/header"


export const searchByName = (searchString, region) => async (dispatch) => {
    // this action creator returns an async function, which is possible because of the thunk middleware applied in our base index.js.
    // we pass a good ol' plain JS object to dispatch, which is what eventually gets passed to the reducers once the middleware has run
    if (searchString) {
        const response = await api.get(`/bigtrees/btsearch?searchString=${searchString}&region=${region}`, auth);
        return dispatch({ type: "SEARCH_BY_NAME", payload: response });
    } else {
        return dispatch({ type: "SEARCH_BY_NAME", payload: [] })
    }

};

export const linkSearchByName = async (searchString) => {
    try {
        if (searchString) {
            const response = await api.get(`/link-search-by-name?searchString=${searchString}`);
            return response;
        }
    } catch (err) {
        console.log(`actions.search.linkSearchByName: ${err}`)
    }
}

export const searchByFamilyGenus = (searchString, region) => async (dispatch) => {
    // this action creator returns an async function, which is possible because of the thunk middleware applied in our base index.js.
    // we pass a good ol' plain JS object to dispatch, which is what eventually gets passed to the reducers once the middleware has run
    if (searchString) {
        const response = await api.get(`/search-by-family-genus?searchString=${searchString}&region=${region}`);
        return dispatch({ type: "SEARCH_BY_FAMILY_GENUS", payload: response });
    } else {
        return dispatch({ type: "SEARCH_BY_FAMILY_GENUS", payload: [] })
    }

};

export const setSelectedTree = (treeName) => {
    return { type: "SET_SELECTED_TREE", payload: treeName };
};

export const setSearchOptions = (searchOptions) => {
    try {
        if (searchOptions) {
            return { type: "SET_SEARCH_OPTIONS", payload: searchOptions }
        }
    } catch (err) {
        console.log(`actions.search.setSearchOptions: ${err}`);
    }
}

export const getSearchResultsPageData = (filterData, activePage, resultsPerPage, sort) => async (dispatch) => {
    try {
        await api.get(`/search-results?filterData=${filterData}&activePage=${activePage}&resultsPerPage=${resultsPerPage}&sort=${sort}`, auth)
            .then(response => {
                if (response) {
                    return dispatch({ type: "SEARCH_RESULTS_PAGE_DATA", payload: response });
                } else {
                    throw new Error(`no response from api`);
                }
            })
            .catch(err => {
                throw new Error(err)
            });

    } catch (err) {
        console.log(`actions.search.getSearchResultsPageData: ${err}`);
        return dispatch({ type: "SEARCH_RESULTS_PAGE_DATA", payload: [] });
    }
}

export const getSearchByNameMultiResult = (searchTerm, activePage, resultsPerPage, sort, region) => async (dispatch) => {
    try {
        await api.get(`/search-by-name-multiresult?region=${region}&searchTerm=${searchTerm}&activePage=${activePage}&resultsPerPage=${resultsPerPage}&sort=${sort}`, auth)
            .then(response => {
                return dispatch({ type: "SEARCH_BY_NAME_MULTIRESULT_DATA", payload: response });
            })
    } catch (err) {
        console.log(`actions.search.getSearchByNameMultiResult: ${err}`);
    }
}