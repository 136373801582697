import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select'
import './FormField.css'

function MenuInput(props) {
   const {label, onChange, required, id, name, hasError, errorMessage, ...inputProps} = props;
   const [value, setValue] = useState("");
   const [edited, setEditStatus] = useState("false")

   function decideColor(state, error) {
      if (state.isFocused)
         return '1px solid #457537';
      else if (error && value === "")
         return '1px solid red';
      
      return '1px solid lighgrey';
   }
   
   const selectStyle = {
       option: (provided, state) => ({
           ...provided,
           "&:active": {
               backgroundColor: "#457537" // background color when clicked
           },
           backgroundColor: state.isFocused ? "#457537" : "white",
           color: state.isFocused ? "white" : "black",
       }),
       control: (base, state) => ({
           ...base,
           '&:hover': { borderColor: '#457537' }, // border style on hover
           border: decideColor(state, hasError),
           boxShadow: state.isFocused ? '5px #457537' : 0
       }),
       menu: (provided) => ({
           ...provided,
           zIndex: 9999
       })

   };

   return (
      <Form.Group className='formInput'> 
         <Form.Label>
            {label} 
            {props.required?<a style={{color:"red"}}> *</a>: ""}
         </Form.Label>
         <Select 
            className='react-select'
            {...inputProps}
            styles={selectStyle}
            onChange={e => {
               setValue(e.value)
               props.onChange(name, e.value)
            }}
            onBlur={() => setEditStatus("true")}
            />
         <input style={{display: 'none'}} 
            onChange={e =>{}}
            required={required}
            value={value}
            edited={edited}
            />
         <span className='error-message'>{errorMessage}</span>
      </Form.Group>
   )
}

export default MenuInput
