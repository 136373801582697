const checkRole = (role_values) => {
    let user = currentUser()
    return role_values.indexOf(user.role_id) >= 0
}


const currentUser = () => {
    let user = {}
    try {
        let localUser = JSON.parse(localStorage.getItem('user'))
        if (localUser && localUser.id) { user = localUser }
        else (user = {})
        return user
    } catch (err) {
        console.log(`Error in components.UserAccount.render: ${err}`);
        return {}
    }
}

export {
    checkRole, 
    currentUser
}
