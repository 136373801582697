import React from "react";
import { Form } from "react-bootstrap";

import CreatableSelect from 'react-select/creatable'

// react-select styling options
import reactSelectStyles from "../../customUI/react-select-options";
import "./FormField.css";


class FormFieldMultiText extends React.Component {
    constructor(props) {
        super();

        if (props.parentComponent.state) {
            let value = props.parentComponent.state[props.field]
            if (value === undefined || value === null) {
                console.log(`Components.FormField.FormFieldMultitext- Cannot find field ${props.field} on the parent component.`)
            }
            else if (!Array.isArray(value)) {
                console.log(`Components.FormField.FormFieldMultitext- Please make sure ${props.field} on the parent component defaults to an array.`)
            }
        } else {
            console.log(`Components.FormField.FormFieldMultitext- Please pass in the parent component that has the state.`)
        }

        this.state = {
        }
    }

    change = (selected) => {
        try {
            if (!selected) { selected = [] }

            console.log(selected)

            //Get just the values
            let newValue = selected.map((s) => s.label)
            let field = this.props.field

            if (this.props.reset) {
                this.props.parentComponent.setState({ [field]: newValue, [this.props.reset]: [], changed: true  })
            } else {
                this.props.parentComponent.setState({ [field]: newValue, changed: true  })
            }
        } catch (err) {
            console.log(`Components.FormField.FormFieldMultitext.change()-  ${err}`)
            return null
        }
    }

    render() {
        let defaultValues = [];
        
        let values = this.props.parentComponent.state[this.props.field]
    
        if (values) {
            values.forEach((chosenValue) => {
                //Map over the values (plain text) and grab the options ({value, label} object)
                let options = this.props.options
                if(options) {
                    //This component allows 
                    let found = false
                    options.forEach((option) => {
                        if (chosenValue === option.label) { 
                            defaultValues.push(option) 
                            found = true
                        }
                    })
                    //If it's not found in the options, that means it's custom- create the ({value, label} object)
                    if (!found) {
                        defaultValues.push({label: chosenValue, value: chosenValue})
                    }
                } else {            
                    console.log(`Components.FormField.FormFieldMultitext.render()- No options for ${this.props.field}`)
                }
            })
        } else {
            console.log(`Components.FormField.FormFieldMultitext.render()- Cannot find ${this.props.field} on parent component`)
        }

        return (
            <Form.Group className="form-field-multitext">
                <Form.Label>{this.props.title}</Form.Label>
                <CreatableSelect
                    defaultValue={defaultValues}
                    isMulti
                    closeMenuOnSelect={false}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={this.props.options}
                    styles={reactSelectStyles}
                    onChange={this.change}
                    value={defaultValues} />
            </Form.Group>
        )
    }
}

export default FormFieldMultiText